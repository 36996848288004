// src/components/admin/SKUList/SKUList.tsx
import React from 'react';
import { FaEdit, FaEye, FaTrash } from 'react-icons/fa';

interface Sku {
    name: string;
    id: string;
    created_at: string;
}

interface SKUListProps {
    skuData: Sku[];
    onView: (sku: Sku) => void;
    onEdit: (sku: Sku) => void;
    onDelete: (id: string) => void;
}

const SKUList: React.FC<SKUListProps> = ({ skuData, onView, onEdit, onDelete }) => {
    return (
        <table className="table table-bordered">
            <thead className="table-success">
                <tr>
                    <th>SKU Name</th>
                    <th>SKU ID</th>
                    <th>Created Date</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {skuData.map((item, index) => (
                    <tr key={index}>
                        <td>{item.name}</td>
                        <td>{item.id}</td>
                        <td>{item.created_at}</td>
                        <td>
                            <button className="btn btn-outline-primary btn-sm me-2" title="View" onClick={() => onView(item)}>
                                <FaEye />
                            </button>
                            <button className="btn btn-outline-success btn-sm me-2" title="Edit" onClick={() => onEdit(item)}>
                                <FaEdit />
                            </button>
                            <button className="btn btn-outline-danger btn-sm" title="Delete" onClick={() => onDelete(item.id)}>
                                <FaTrash />
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default SKUList;
