import React, { useEffect, useState } from 'react';
import BuyerSetModal from '../../../components/admin/BuyerSets/BuyerSetModal.tsx';
import BuyerSetsTable from '../../../components/admin/BuyerSets/BuyerSetsTable.tsx';
import { createBuyer, editBuyer, fetchBuyers } from '../../../utils/api/admin';
import './style.css';

interface BuyerSet {
  id: number;
  buyer_set_id: string;
  buyer_set_name: string;
  buyer_counts: number;
  handler_id_check: number;
  QR: string;
  created_at: string;
}

const BuyerSets: React.FC = () => {
  const [groupOffers, setGroupOffers] = useState<BuyerSet[]>([]);
  const [activeModal, setActiveModal] = useState<string | null>(null);
  const [formData, setFormData] = useState<BuyerSet>({
    id: 0,
    buyer_set_id: '',
    created_at: '',
    buyer_set_name: '',
    buyer_counts: 0,
    handler_id_check: 0,
    QR: '',
  });
  const [selectedImage, setSelectedImage] = useState<File | null>(null);

  const fetchData = async () => {
    try {
      const buyers = await fetchBuyers();
      setGroupOffers(buyers);
    } catch (error) {
      console.error('Failed to fetch buyer sets:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleModalToggle = (modalName: string) => {
    setActiveModal((prev) => (prev === modalName ? null : modalName));
  };

  const handleClose = () => {
    // Reset form data and selected image when modal is closed
    setFormData({
      id: 0,
      buyer_set_id: '',
      created_at: '',
      buyer_set_name: '',
      handler_id_check: 0,
      buyer_counts: 0,
      QR: '',
    });
    setSelectedImage(null);
    setActiveModal(null); // Close the modal
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleImageSelect = (imageFile: File) => {
    setSelectedImage(imageFile);
  };

  const handleFormSubmit = async (e: React.FormEvent, isEdit: boolean) => {
    e.preventDefault();

    if (!formData.buyer_set_name || !formData.buyer_set_id) {
      alert('Please fill in all required fields.');
      return;
    }

    try {
      if (isEdit) {
        await editBuyer(
          formData.buyer_set_name,
          formData.buyer_set_id,
          formData.created_at,
          formData.id
        );
        const updatedGroupOffers = groupOffers.map((offer) =>
          offer.buyer_set_id === formData.buyer_set_id
            ? { ...offer, ...formData, QR: selectedImage ? URL.createObjectURL(selectedImage) : offer.QR }
            : offer
        );
        setGroupOffers(updatedGroupOffers);
        alert('Buyer set updated successfully');
      } else {
        const newBuyerSet = await createBuyer(
          formData.buyer_set_name,
          formData.handler_id_check,
          selectedImage
        );
        setGroupOffers([...groupOffers, { ...formData, ...newBuyerSet }]);
        alert('Buyer set created successfully');
      }
      await fetchData();

    } catch (error) {
      console.error('Failed to submit form:', error);
      alert('Error occurred while processing the request');
    }

    handleClose(); // Reset modal fields after submission
  };

  return (
    <div className="container">
      <BuyerSetModal
        showModal={activeModal === 'create' || activeModal === 'edit'}
        handleClose={handleClose} // Use handleClose to reset fields on modal close
        formData={formData}
        handleInputChange={handleInputChange}
        handleImageSelect={handleImageSelect}
        handleFormSubmit={handleFormSubmit}
        isEdit={activeModal === 'edit'}
      />

      <div className="heading-container align-items-center d-flex justify-content-between">
        <h4 className="mb-0 adminTitle">View Buyer Sets</h4>
        <button className="create-group-offer-button2" onClick={() => handleModalToggle('create')}>
          Create Buyer Set
        </button>
      </div>

      <BuyerSetsTable
        groupOffers={groupOffers}
        onEdit={(offer) => {
          setFormData(offer);
          handleModalToggle('edit');
        }}
      />
    </div>
  );
};


export default BuyerSets;
