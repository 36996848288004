import React from "react";

const TermsConditions: React.FC = () => {
    return (
        <div className="bodyScroll">
            <div className="container">
                <div className="d-flex justify-content-center line-title my-5">
                    <h2>Terms And Conditions</h2>
                </div>
                <div className="card overflow-hidden mb-4 p-4">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <p className="text-muted">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                            <p className="text-muted">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                            <h2 className="mb-3">List Details</h2>
                            <p className="mb-2">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                            <ul className="list-dotted text-start g-5">
                                <li className="mb-4">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </li>
                                <li className="mb-4">
                                    Lorem the 1500s, when an unknown printer took a galley.
                                </li>
                                <li className="mb-4">
                                    Lorem the 1500s, when an unknown printer took a galley of type and scrambled it.
                                </li>
                                <li className="mb-4">
                                    Lorem the 1500s, when an unknown printer took a galley.
                                </li>
                            </ul>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TermsConditions;
