import React from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

interface ModalCreatePackageProps {
    show: boolean;
    onHide: () => void;
    formData: any;
    onInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onSubmit: (e: React.FormEvent) => void;
}

const ModalCreatePackage: React.FC<ModalCreatePackageProps> = ({ show, onHide, formData, onInputChange, onSubmit }) => (
    <Modal show={show} onHide={onHide} size="lg" centered>
        <Modal.Header closeButton className="border-0">
            <Modal.Title className="fw-bold">Create PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0 mt-0">
            <Form onSubmit={onSubmit}>
                <Row className="g-2 align-items-center mb-3">
                    <Col md={6}>
                        <Form.Group controlId="installerCompanyName">
                            <Form.Label>Installer Company Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Installer Company Name"
                                name="installerCompanyName"
                                value={formData.installerCompanyName}
                                onChange={onInputChange}
                                className="rounded-pill"
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="installationDate">
                            <Form.Label>Installation Date</Form.Label>
                            <Form.Control
                                type="date"
                                name="installationDate"
                                value={formData.installationDate}
                                onChange={onInputChange}
                                className="rounded-pill"
                            />
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <Form.Group controlId="installerCompanyAddress">
                            <Form.Label>Installer Company Address</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Enter Address"
                                name="installerCompanyAddress"
                                value={formData.installerCompanyAddress}
                                onChange={onInputChange}
                                rows={3}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <Form.Group controlId="installerCompanyDescription">
                            <Form.Label>Installer Company Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Enter a brief description of the installer company"
                                name="installerCompanyDescription"
                                value={formData.installerCompanyDescription}
                                onChange={onInputChange}
                                rows={3}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} className="mx-auto mt-3">
                        <Button
                            variant="dark"
                            type="button"
                            onClick={onSubmit}
                            className="w-100 rounded-pill"
                        >
                            Create PDF
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Modal.Body>
    </Modal>
);

export { ModalCreatePackage };
