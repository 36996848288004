import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import csvFile from '../../../Image/csvFile.svg'; // Default upload icon

interface Props {
  onImageSelect: (imageFile: File) => void;
}

const ImageUploadComponent: React.FC<Props> = ({ onImageSelect }) => {
  const [fileName, setFileName] = useState<string>(''); // To hold the file name
  const [statusMessage, setStatusMessage] = useState<string>(''); // To hold status message
  const [statusIcon, setStatusIcon] = useState<string>(''); // To hold the status icon (✔ or ❌)

  const handleQrUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const isCsvFile = file.type === 'text/csv';
      if (isCsvFile) {
        setFileName(file.name); // Store the file name
        setStatusMessage('Upload successful'); // Success message
        setStatusIcon('✔'); // Checkmark for success
        onImageSelect(file);
      } else {
        setFileName(''); // Clear the file name if the file is invalid
        setStatusMessage('Upload failed: Please upload a .csv file'); // Error message
        setStatusIcon('❌'); // Cross mark for error
      }
    } else {
      setFileName(''); // Clear the file name when no file is selected
      setStatusMessage(''); // Clear status message
      setStatusIcon(''); // Clear the icon
    }
  };

  return (
    <div className="row">
      <Col md={10} mt={3} className="d-flex align-items-center flex-column">
        <div className="forQr position-relative text-center">
          {/* Show file name if a file is selected, else show default upload message */}
          {!fileName ? (
            <img
              className="m-auto d-flex qrImg w-100 forBuyerQr"
              src={csvFile} // Your default CSV upload icon
              alt="Upload a .csv file"
            />
          ) : (
            <p className="mb-2">{fileName}</p> // Show the file name after selection
          )}
          <input 
            className="hideInput" 
            type="file" 
            onChange={handleQrUpload} 
            accept=".csv" 
            aria-label="Upload CSV file"
          />
        </div>

        {/* Display status message and icon */}
        {statusMessage && (
          <div className="d-flex align-items-center mt-2">
            <p className={`mb-0 ${statusIcon === '✔' ? 'text-success' : 'text-danger'}`}>
              {statusMessage}
            </p>
            {statusIcon && (
              <span className="ms-2" style={{ fontSize: '20px' }}>
                {statusIcon}
              </span>
            )}
          </div>
        )}
      </Col>
    </div>
  );
};

export default ImageUploadComponent;
