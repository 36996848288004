import React from 'react';

interface RecurringItem {
  id: number;
  description: string;
  quantity: number;
  price: number;
}

interface RecurringItemTableProps {
  recurringItems: RecurringItem[];
  onRecurringChange: (id: number, field: string, value: string | number) => void;
}

const RecurringItemTable: React.FC<RecurringItemTableProps> = ({ recurringItems, onRecurringChange }) => {
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>S.N.</th>
          <th>Description</th>
          <th>Size/Quantity</th>
          <th>Price</th>
          <th>Total Price</th>
        </tr>
      </thead>
      <tbody>
        {recurringItems.map((item, index) => (
          <tr key={item.id}>
            <td>{index + 1}</td>
            <td>
              <input
                type="text"
                value={item.description}
                onChange={(e) => onRecurringChange(item.id, 'description', e.target.value)}
                placeholder="Enter description"
                className="form-control"
              />
            </td>
            <td>
              <input
                type="number"
                value={item.quantity || ''}
                onChange={(e) => onRecurringChange(item.id, 'quantity', e.target.value)}
                placeholder="Enter quantity"
                className="form-control"
                min="0"
              />
            </td>
            <td>
              <input
                type="number"
                value={item.price || ''}
                onChange={(e) => onRecurringChange(item.id, 'price', e.target.value)}
                placeholder="Enter price"
                className="form-control"
                min="0"
              />
            </td>
            <td>${item.quantity * item.price || 0}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default RecurringItemTable;
