import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SKUList from '../../../components/admin/SKUList/SKUListTable.tsx'; // Import the table component
import SKUModal from '../../../components/admin/SKUList/SKUModal.tsx';
import successImage from '../../../Image/success.gif';
import { deleteSku, fetchSku } from '../../../utils/api/admin.js';

interface Sku {
	name: string;
	id: string;
	created_at: string;
}

const SKUListTable = () => {
	const navigate = useNavigate();
	const [selectedSku, setSelectedSku] = useState<Sku | null>(null);
	const [editMode, setEditMode] = useState(false);
	const [skuData, setSkuData] = useState([]);
	const [successModalVisible, setSuccessModalVisible] = useState(false);
	const [deleteModalVisible, setDeleteModalVisible] = useState(false);
	const [skuToDelete, setSkuToDelete] = useState<string | null>(null); // Store the SKU ID to delete

	const fetchData = async () => {
		try {
			const buyers = await fetchSku();
			setSkuData(buyers);
		} catch (error) {
			console.error('Failed to fetch SKU list:', error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const handleView = (sku: Sku) => {
		navigate(`/admin/sku-view/${sku.id}`, { state: sku }); // Pass data to the view page
	};

	const handleEdit = (sku: Sku) => {
		navigate(`/admin/sku-edit/${sku.id}`, { state: sku }); // Pass data to the view page
	};

	const handleDeleteClick = (id: string) => {
		setSkuToDelete(id);
		setDeleteModalVisible(true); // Show the confirmation modal
	};

	const handleDelete = async () => {
		if (skuToDelete) {
			try {
				await deleteSku(skuToDelete);
				setSkuData(skuData.filter((item) => item.id !== skuToDelete));
				setDeleteModalVisible(false); // Close delete confirmation modal
				setSuccessModalVisible(true); // Show success modal
			} catch (error) {
				console.error('Failed to delete SKU:', error);
				alert('Error deleting SKU. Please try again.'); // Optional: Notify the user
			}
		}
	};

	return (
		<div className="container-fluid">
			<div className="group-offers-container">
				<div className="heading-container align-items-center d-flex justify-content-between">
					<h4 className="mb-0 adminTitle">SKU List</h4>
					<Link
						to="/admin/sku-new"
						className={`text-decoration-none create-group-offer-button2 active`}
					>
						Create New SKU
					</Link>
				</div>

				{/* SKU Table Component */}
				<SKUList
					skuData={skuData}
					onView={handleView}
					onEdit={handleEdit}
					onDelete={handleDeleteClick}
				/>

				{/* SKU Modal Component */}
				{selectedSku && (
					<SKUModal
						sku={selectedSku}
						editMode={editMode}
						onClose={() => setSelectedSku(null)}
						onSaveEdit={(updatedSku) => {
							setSkuData((prevData) =>
								prevData.map((item) =>
									item.id === updatedSku.id ? { ...updatedSku } : item
								)
							);
							setSelectedSku(null);
						}}
					/>
				)}

				{/* Success Modal */}
				<div
					className={`modal fade ${successModalVisible ? 'show' : ''}`}
					tabIndex={-1}
					style={{
						display: successModalVisible ? 'block' : 'none',
						backgroundColor: 'rgba(0, 0, 0, 0.5)',
					}}
					role="dialog"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<button
									type="button"
									className="btn-close"
									onClick={() => setSuccessModalVisible(false)}
									aria-label="Close"
								></button>
							</div>
							<div className="modal-body text-center d-flex flex-column align-items-center justify-content-center">
								<img
									className="success"
									src={successImage}
									alt="Success"
									style={{ maxWidth: '100%', height: 'auto' }}
								/>
								<div className="mt-3">SKU deleted successfully!</div>
							</div>
						</div>
					</div>
				</div>

				{/* Delete Confirmation Modal */}
				<div
					className={`modal fade ${deleteModalVisible ? 'show' : ''}`}
					tabIndex={-1}
					style={{
						display: deleteModalVisible ? 'block' : 'none',
						backgroundColor: 'rgba(0, 0, 0, 0.5)',
					}}
					role="dialog"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Confirm Deletion</h5>
								<button
									type="button"
									className="close bg-transparent border-0"
									onClick={() => setDeleteModalVisible(false)}
									aria-label="Close"
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								Are you sure you want to delete this SKU?
							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-secondary"
									onClick={() => setDeleteModalVisible(false)}
								>
									Cancel
								</button>
								<button
									type="button"
									className="btn btn-danger"
									onClick={handleDelete}
								>
									Yes, Delete
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SKUListTable;
