import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCheckCircle,
	faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

interface SuccessModalProps {

  isOpen: boolean;
  onClose: () => void;
  isSuccess: boolean; // Track if the operation was successful
  message: string; // Custom message to display in the modal
  navigateTo: string | null; // Custom message to display in the modal
}

const SuccessModal: React.FC<SuccessModalProps> = ({
  isOpen,
  onClose,
  isSuccess,
  message,
  navigateTo = null,
}) => {
  const navigate = useNavigate();

  return (
    <Modal show={isOpen} onHide={onClose} centered className="border-0 customDialog">
      <Modal.Body className="pt-0 mt-0 text-center">
        {/* Displaying the FontAwesome icon with margin */}
        <FontAwesomeIcon
          icon={isSuccess ? faCheckCircle : faTimesCircle}
          size="5x" // Large size for emphasis
          className={isSuccess ? 'text-success' : 'text-danger'}
          style={{
            marginTop: '20px',
            marginBottom: '15px',
            color: isSuccess ? 'green' : 'red', // Force red for failure
          }}
        />

        {/* Displaying the dynamic message with increased font size */}
        <h5
          className="text-center mb-4 mt-4 theme-color"
          style={{
            fontSize: '1.25rem',
            fontWeight: 'bold',
            color: isSuccess ? 'green' : 'red', // Force red for failure
          }}
        >
          {message}
        </h5>

        {/* Close Button */}
        <Button
          type="button"
          className="w-50 mx-auto create-group-offer-button2"
          onClick={() => {
            onClose();
            if (navigateTo) navigate(navigateTo);
          }}
          aria-label="Close"
        >
          Ok
        </Button>
      </Modal.Body>
    </Modal>
  );

};

export default SuccessModal;
