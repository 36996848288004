import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import logoWithText from '../../Image/logo-with-text.svg';
import notificationIcon from '../../Image/notification.svg';
import optionsIcon from '../../Image/threeDots.svg';
import toggleIcon from '../../Image/toggler.svg';

const AdminHeader: React.FC = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLLIElement>(null);

  const handleToggleSidebar = () => {
    const adminLayout = document.querySelector('.admin-layout');
    if (adminLayout) {
      adminLayout.classList.toggle('admin-layout-sm');
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  // Close the dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <nav className="navbar navbar-expand-lg adminNav w-100 d-flex" style={{ top: "0", left: "0", position: "fixed" }}>
      <div className="container-fluid d-flex justify-content-between align-items-center">
        <span className="navbar-brand">
          <Link to="/">
            <img className="logo-with-text" src={logoWithText} alt="Logo" />
          </Link>
        </span>
        <ul className="list-unstyled mb-0 d-flex gap-3 ms-auto">
          <li>
            <button className="btn btn-link d-flex align-items-center p-0">
              <img src={notificationIcon} alt="Notifications" />
            </button>
          </li>
          <li ref={dropdownRef} className="position-relative">
            <button
              className="btn btn-link d-flex align-items-center p-0 h-100"
              onClick={() => setIsDropdownOpen((prev) => !prev)}
            >
              <img src={optionsIcon} alt="Options" />
            </button>
            {isDropdownOpen && (
              <div className="custom-dropdown-menu position-absolute end-0 mt-2 p-2 bg-dark text-white rounded shadow">
                <button className="dropdown-item text-white bg-transparent border-0" onClick={handleLogout}>
                  Logout
                </button>
              </div>
            )}
          </li>
          <li className="d-lg-none d-md-flex">
            <button
              className="btn btn-link p-0 d-flex align-items-center"
              onClick={handleToggleSidebar}
            >
              <img src={toggleIcon} alt="Toggle Sidebar" />
            </button>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default AdminHeader;
