import React from 'react';
import FatArrow from './FatArrow.tsx';


interface Step {
  label: string;
  isHighlighted: boolean;
}

interface OrderData {
  orderNo: string;
  buyerId: string;
  steps: Step[];
}

interface OrderRowProps {
  orderData: OrderData;
  isChecked: boolean;
  handleCheckboxChange: (orderNo: string) => void;
  showCheckboxColumn: boolean;
}

const OrderRow: React.FC<OrderRowProps> = ({ orderData, isChecked, handleCheckboxChange, showCheckboxColumn }) => {
  return (
    <tr>
      <td className="order-info Table-heading-order">
        <div className="buyer-id">Buyer ID: {orderData.buyerId}</div>
        <div className="order-number">Order ID: </div>
      </td>
      <td className="workflow-steps"
      style={{paddingRight:'25px'}}>
        {orderData.steps.map((step, index) => (
          <FatArrow key={index} content={step.label} isHighlighted={step.isHighlighted} />
        ))}
      </td>
      {/* {showCheckboxColumn && (
        <td className="checkbox-cell">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={() => handleCheckboxChange(orderData.orderNo)}
          />
        </td>
      )} */}
    </tr>
  );
};

export default OrderRow;
