import React from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

interface SendGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
  onNext: () => void;
}

const SendGroupModal: React.FC<SendGroupModalProps> = ({ isOpen, onClose, onNext }) => {
  const [formData, setFormData] = React.useState({
    groupName: '',
    skuId: ''
  });

  // Handle form input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <Modal show={isOpen} onHide={onClose} centered className="border-0">
      <Modal.Header closeButton className="border-0 pb-0">
        <Modal.Title className="fw-bold text-center">Send Group</Modal.Title>
      </Modal.Header>
      <Modal.Body className="mt-0 pt-0">
        <Row>
          <Col md={12} className="mb-3">
            <Form.Group controlId="groupName">
              <Form.Label>Group Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Group Name"
                name="groupName"
                value={formData.groupName}
                onChange={handleInputChange}
                className="rounded-pill"
              />
            </Form.Group>
          </Col>
          <Col md={12} className="mb-3">
            <Form.Group controlId="skuId">
              <Form.Label>Select the Buyer Set</Form.Label>
              <Form.Select
                name="skuId"
                value={formData.skuId}
                onChange={handleInputChange}
                className="rounded-pill"
              >
                <option value="">Select Buyer Set</option>
                <option value="Buyer-Set-1">Buyer Set-1</option>
                <option value="Buyer-Set-2">Buyer Set-2</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6} className="mx-auto mt-3">
            <Button variant="dark" onClick={onNext} className="w-100 create-group-offer-button2">
              Next
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default SendGroupModal;
