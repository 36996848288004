// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.group-offers-container {
    padding: 20px;
    border-radius: 8px;
    margin: 0 auto;
  }
  .heading-container h2 {
    text-align: left;
    color: #333;
}

.create-group-offer-button2 {
    background-color:  #453D4D;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 63px;
    cursor: pointer;
    /* float: right; */
    /* margin-top: 1.5%; */
  }
  .group-offer-table-main{
    background-color: white;
    border-radius: 5px;
  
    margin-top: 20px;
  }
  .dropdown-menu{
    left: inherit !important;
    right: 0 !important;
  }
  .group-offers-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .group-offers-table th,
  .group-offers-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .group-offers-table th {
    font-weight: bold;

  }
  .group-offers-table tbody{
    padding-top: 15px;
    padding-bottom: 151px;
  }
  
`, "",{"version":3,"sources":["webpack://./src/pages/admin/GroupOffers/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,cAAc;EAChB;EACA;IACE,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,0BAA0B;IAC1B,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,kBAAkB;IAClB,sBAAsB;EACxB;EACA;IACE,uBAAuB;IACvB,kBAAkB;;IAElB,gBAAgB;EAClB;EACA;IACE,wBAAwB;IACxB,mBAAmB;EACrB;EACA;IACE,WAAW;IACX,yBAAyB;EAC3B;;EAEA;;IAEE,aAAa;IACb,gBAAgB;IAChB,6BAA6B;EAC/B;;EAEA;IACE,iBAAiB;;EAEnB;EACA;IACE,iBAAiB;IACjB,qBAAqB;EACvB","sourcesContent":[".group-offers-container {\n    padding: 20px;\n    border-radius: 8px;\n    margin: 0 auto;\n  }\n  .heading-container h2 {\n    text-align: left;\n    color: #333;\n}\n\n.create-group-offer-button2 {\n    background-color:  #453D4D;\n    color: white;\n    padding: 10px 20px;\n    border: none;\n    border-radius: 63px;\n    cursor: pointer;\n    /* float: right; */\n    /* margin-top: 1.5%; */\n  }\n  .group-offer-table-main{\n    background-color: white;\n    border-radius: 5px;\n  \n    margin-top: 20px;\n  }\n  .dropdown-menu{\n    left: inherit !important;\n    right: 0 !important;\n  }\n  .group-offers-table {\n    width: 100%;\n    border-collapse: collapse;\n  }\n  \n  .group-offers-table th,\n  .group-offers-table td {\n    padding: 10px;\n    text-align: left;\n    border-bottom: 1px solid #ddd;\n  }\n  \n  .group-offers-table th {\n    font-weight: bold;\n\n  }\n  .group-offers-table tbody{\n    padding-top: 15px;\n    padding-bottom: 151px;\n  }\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
