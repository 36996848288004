import React, { FC } from 'react';
import gr from '../../Image/cash_savings_on_homes.jpg';

interface AboutProps {
	heading: string;
}

const About: FC<AboutProps> = ({ heading }) => {
	return (
		<div className="bodyScroll">
			<div className="d-flex justify-content-center line-title mt-2 mb-5">
				<h2>Solutions</h2>
			</div>
			<div>
				<div className="container">
					<div className="card overflow-hidden mb-4 p-4">
						{/* Section: What Is Our Plan */}
						<div className="row align-items-center mb-5 border-bottom pb-4">
							<h2 className="mb-4">— WHAT IS OUR SOLUTION —</h2>
							<div className="row align-items-center">
								<div className="col-md-5">
									<img src={gr} alt="Our Plan Image" className="w-100 pb-3" />
								</div>
								<div className="col-md-7">
									<p className="text-muted">
										<strong>Synergy</strong> is accelerating the clean energy
										revolution with Community Solar or simply{' '}
										<strong>ComSol</strong>. Global energy generation is 7 terra
										watts and 1.7 terra watts just here in the United States.
										All of this energy cannot be decarbonized, there is a cost
										for the green premium.
									</p>
									<p className="text-muted">
										Synergy ComSol offers a turnkey solution for your renewable
										energy needs and maintains a balance over the green premium.
									</p>
									<p className="text-muted">
										Sustainable clean energy with a turnkey offer:
									</p>
									<ul className="list-dotted text-start g-5">
										<li className="text-muted">
											Optimized sizing for your daily and peak energy demand.
										</li>
										<li className="text-muted">
											Buy with confidence along with your community.
										</li>
										<li className="text-muted">
											Get the 30% federal credits on your renewable energy
											installations.
										</li>
										<li className="text-muted">
											Charge your EV at almost no charge during the day.
										</li>
										<li className="text-muted">
											Get month-on-month savings on your electric bills.
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default About;
