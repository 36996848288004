import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_URL; // Replace with your backend API URL

// Create an Axios instance with global configuration
const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
// Interceptor to add the Bearer token to each request
const token = localStorage.getItem("token"); // Retrieve the token from local storage or another source

api.interceptors.request.use(
  (config) => {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const fetchBuyers = async () => {
  try {
    const response = await api.get("/admin/buyer/index");
    return response?.data?.data;
  } catch (error) {
    throw error.response?.data || "Failed to fetch data";
  }
};

export const createBuyer = async (
  buyer_set_name,
  handler_id_check,
  buyercsv
) => {
  try {
    // Create a FormData object
    const formData = new FormData();
    formData.append("buyer_set_name", buyer_set_name);
    formData.append("handler_id_check", handler_id_check);
    formData.append("buyercsv", buyercsv); // Assuming 'buyercsv' is a File object

    // Retrieve the token from local storage
    const token = localStorage.getItem("token");

    // Send the request with multipart/form-data
    const response = await axios.post(API_BASE_URL+"/admin/buyer/create", formData, {
      headers: {
        "Content-Type": "multipart/form-data", // Explicitly set Content-Type
        Authorization: `Bearer ${token}`, // Include the Bearer token
      },
    });

    return response?.data?.data;
  } catch (error) {
    throw error.response?.data || "Failed to fetch data";
  }
};

export const editBuyer = async (
  buyer_set_name,
  buyer_set_id,
  buyer_set_create_date,
  id
) => {
  try {
    const response = await api.post("/admin/buyer/update", {
      buyer_set_name,
      buyer_set_id,
      buyer_set_create_date,
      id,
    });
    return response?.data?.data;
  } catch (error) {
    throw error.response?.data || "Failed to fetch data";
  }
};

export const buyerDetails = async (id) => {
  try {
    const response = await api.post("/admin/buyerHandler/by/buyerid", { id });
    return response?.data?.data;
  } catch (error) {
    throw error.response?.data || "Failed to fetch data";
  }
};

export const deleteBuyerHandeler = async (id) => {
  try {
    const response = await api.post("/admin/delete/buyerHandler", { id });
    return response?.data?.data;
  } catch (error) {
    throw error.response?.data || "Failed to fetch data";
  }
};

export const fetchSku = async () => {
  try {
    const response = await api.get("/admin/sku/index");
    return response?.data?.data;
  } catch (error) {
    throw error.response?.data || "Failed to fetch data";
  }
};

export const createSku = async (
  name,
  description,
  total,
  item,
  monthly_recurring_charge,
  turn_key_price
) => {
  try {
    const response = await api.post("/admin/sku/create", {
      name,
      description,
      total,
      item,
      monthly_recurring_charge,
      turn_key_price,
    });
    return response?.data?.data;
  } catch (error) {
    throw error.response?.data || "Failed to fetch data";
  }
};

export const getSkuById = async (id) => {
  try {
    const response = await api.post("/admin/sku/edit", { id });
    return response?.data?.data;
  } catch (error) {
    throw error.response?.data || "Failed to fetch data";
  }
};

export const updateSku = async (id, skuData) => {
  try {
    const response = await api.post("/admin/sku/update", { id, ...skuData });
    return response?.data;
  } catch (error) {
    throw error.response?.data || "Failed to fetch data";
  }
};

export const deleteSku = async (id) => {
  try {
    const response = await api.post("/admin/sku/delete", { id });
    return response?.data;
  } catch (error) {
    throw error.response?.data || "Failed to fetch data";
  }
};

export const fetchgroupOffers = async () => {
  try {
    const response = await api.get("/admin/group-offers");
    return response?.data?.data;
  } catch (error) {
    throw error.response?.data || "Failed to fetch data";
  }
};

export const sendPostCard = async (id) => {
  try {
    const response = await api.post("/admin/send-postcard", { id });
    return response?.data?.data;
  } catch (error) {
    throw error.response?.data || "Failed to crerate data";
  }
};

export const groupoffersupdate = async (
  name,
  start_date,
  end_date,
  sku_id,
  list_price,
  discount,
  discount_price,
  id
) => {
  try {
    const response = await api.post("/admin/group-offers/update", {
      name,
      start_date,
      end_date,
      sku_id,
      list_price,
      discount,
      discount_price,
      id
    });
    return response?.data;
  } catch (error) {
    throw error.response?.data || "Failed to update data";
  }
};
export const groupofferscreate = async (
  name,
  start_date,
  end_date,
  sku_id,
  list_price,
  discount,
  discount_price
) => {
  try {
    const response = await api.post("/admin/group-offers/create", {
      name,
      start_date,
      end_date,
      sku_id,
      list_price,
      discount,
      discount_price,
    });
    return response?.data;
  } catch (error) {
    throw error.response?.data || "Failed to fetch data";
  }
};

export const groupofferSendgroup = async (
  groupNamevalue,
  buyersetId,
  buyerOptionId
) => {
  try {
    const response = await api.post("/admin/group-offers/send-group", {
      groupNamevalue,
      buyersetId,
      buyerOptionId,
    });
    return response?.data;
  } catch (error) {
    throw error.response?.data || "Failed to fetch data";
  }
};

export const getAvailableBuyerList = async () => {
  try {
    const response = await api.get("/admin/buyer/available");
    return response?.data;
  } catch (error) {
    throw error.response?.data || "Failed to fetch data";
  }
};

export const getbuyerlist = async () => {
  try {
    const response = await api.get("/admin/buyer/index");
    return response?.data;
  } catch (error) {
    throw error.response?.data || "Failed to fetch data";
  }
};

export const buyerSetDetails = async (id) => {
  try {
    const response = await api.post("/admin/buyerHandlerDetails/by/buyerid", { id });
    return response?.data?.data;
  } catch (error) {
    throw error.response?.data || "Failed to fetch data";
  }
};

export default api;
