import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { getSkuById } from '../../../utils/api/admin'; // Fetch function

interface Item {
    id: number;
    formatted_id: string;
    sku_id: number;
    description: string;
    size: string;
    created_at: string;
    updated_at: string | null;
}

interface MonthlyRecurringCharge {
    id: number;
    sku_id: number;
    description: string;
    size: number;
    price: number;
    total_price: number;
    created_at: string;
    updated_at: string;
}

interface SkuDetails {
    sku: {
        id: number;
        formatted_id: string;
        name: string;
        description: string;
        total: number;
        turn_key_price: number;
        created_at: string;
        updated_at: string;
    };
    items: Item[];
    monthlyRecurringCharges: MonthlyRecurringCharge[];
}

const SKUView: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [skuDetails, setSkuDetails] = useState<SkuDetails | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchSku = async () => {
            try {
                if (!id) throw new Error('SKU ID is missing');
                const response = await getSkuById(id);
                setSkuDetails(response);
            } catch (err) {
                setError('Failed to fetch SKU details. Please try again.');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchSku();
    }, [id]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return (
            <div>
                <p>{error}</p>
                <button onClick={() => navigate('/admin/sku-list')}>Back to List</button>
            </div>
        );
    }

    if (!skuDetails || !skuDetails.sku) {
        return (
            <div>
                SKU data not available.
                <button onClick={() => navigate('/admin/sku-list')}>Back to List</button>
            </div>
        );
    }

    const { sku, items, monthlyRecurringCharges } = skuDetails;

    return (
        <div className="container">
            <div className="group-offers-container">
                <Link to="/admin/sku-list" className="text-decoration-none text-black mb-0">
                    <h4 className="mb-0 adminTitle">
                        &larr; Back to SKU List
                    </h4>
                </Link>

                <div className="card mt-4 p-3">
                    <h4>SKU Details</h4>
                    <div className="mt-3">
                        <p><strong>ID:</strong> {sku.formatted_id}</p>
                        <p><strong>Name:</strong> {sku.name}</p>
                        <p><strong>Description:</strong> {sku.description}</p>
                        <p><strong>Total Turnkey Price:</strong> ${sku.turn_key_price}</p>
                        <p><strong>Monthly Recurring Charge:</strong> ${sku.total}</p>
                        <p><strong>Created At:</strong> {new Date(sku.created_at).toLocaleString()}</p>
                        <p><strong>Updated At:</strong> {new Date(sku.updated_at).toLocaleString()}</p>
                    </div>

                    <h5 className="mt-4">Items</h5>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Description</th>
                                <th>Size</th>
                                <th>Created At</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item) => (
                                <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.description}</td>
                                    <td>{item.size}</td>
                                    <td>{new Date(item.created_at).toLocaleString()}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <h5 className="mt-4">Monthly Recurring Charges</h5>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Description</th>
                                <th>Size</th>
                                <th>Price</th>
                                <th>Total Price</th>
                                <th>Created At</th>
                            </tr>
                        </thead>
                        <tbody>
                            {monthlyRecurringCharges.map((charge) => (
                                <tr key={charge.id}>
                                    <td>{charge.id}</td>
                                    <td>{charge.description}</td>
                                    <td>{charge.size}</td>
                                    <td>${charge.price}</td>
                                    <td>${charge.total_price}</td>
                                    <td>{new Date(charge.created_at).toLocaleString()}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className='mt-3'>

                    <Link
                        to={`/admin/sku-edit/${sku.id}`}
                        className={`text-decoration-none create-group-offer-button2 active`}
                    >
                        Edit SKU
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default SKUView;
