import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL; // Replace with your backend API URL

// Create an Axios instance with global configuration
const api = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Helper functions for login and signup
export const usersignup = async (username, password, phonenumber, confirmpassword, captchaInput) => {
    try {
        const response = await api.post('/users/register', { username, password, phonenumber, confirmpassword, captchaInput });
        return response.data;
    } catch (error) {
        throw error.response?.data || 'Signup failed';
    }
};

export const userlogin = async (username, password) => {
    try {
        const response = await api.post('/users/login', { username, password });
        return response.data;
    } catch (error) {
        throw error.response?.data || 'Login failed';
    }
};

export const adminlogin = async (username, password) => {
    try {
        const response = await api.post('/admin/login', { username, password });
        return response.data;
    } catch (error) {
        throw error.response?.data || 'Login failed';
    }
};



export default api;
