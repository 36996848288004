import React from 'react';
import { Link } from 'react-router-dom';
import edit from "../../../Image/edit.svg";

interface GroupOffer {
  id: number;
  buyer_set_id: string;
  buyer_set_name: string;
  buyer_counts: number;
  handler_id_check: number;
  QR: string;
  created_at: string;
}

interface Props {
  groupOffers: GroupOffer[];
  onEdit: (offer: GroupOffer) => void;
}

const BuyerSetsTable: React.FC<Props> = ({ groupOffers, onEdit }) => {
  return (
    <div className="group-offer-table-main">
      <table className="group-offers-table">
        <thead>
          <tr>
            <th>Buyer Set Name</th>
            <th>Buyer Set ID</th>
            <th className='text-center'>Buyer Count</th>
            <th>Create Date</th>
            <th>More Details</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {groupOffers.length > 0 ? (
            groupOffers.map((offer, index) => (
              <tr key={index}>
                <td>{offer.buyer_set_name}</td>
                <td>{offer.buyer_set_id}</td>
                <td className='text-center'>
                  {offer.buyer_counts}
                </td>
                <td>{new Date(offer.created_at).toLocaleDateString()}</td>
                <td>
                  <Link to={`/admin/buyer-sets/${offer.id}`} className='text-decoration-none'>
                    {/* <Button className="rounded-pill">View More</Button> */}
                    <button className="login-btn-active login-btn ">View More</button>
                  </Link>
                </td>
                <td>
                  <button className='bg-transparent' onClick={() => onEdit(offer)} style={{border: 'none'}}>
                    <img className="success" src={edit} alt="Edit" />
                  </button>
                  
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={6} style={{ color: 'red', textAlign: 'center' }}>
                No Data
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default BuyerSetsTable;
