import React from 'react';
import threeDots from '../../../Image/threeDots.svg';
import ConfirmationModal from './ConfirmationModal.tsx';
import CreateEditGroupOfferModal from './CreateEditModal.tsx';
import SendModal from './SendModal.tsx';
import SuccessModal from './SuccessModal.tsx';

const GroupOffersTable: React.FC<any> = ({
  groupOffers,
  activeModal,
  handleModalToggle,
  formData,
  handleInputChange,
}) => {

  return (
    <>
      <div className="group-offers-container">
        {/* Create/Edit Group Offer Modal */}
        <CreateEditGroupOfferModal
          isOpen={activeModal === 'create' || activeModal === 'edit'}
          onClose={() => handleModalToggle(null)}
          formData={formData}
          onInputChange={handleInputChange}
          onSubmit={() => {}}
          modalType={activeModal}
        />

        {/* Send Group Modal */}
        <SendModal
          isOpen={activeModal === 'second'}
          onClose={() => handleModalToggle(null)}
        />

        {/* Group Offer Confirmation Modal */}
        <ConfirmationModal
          isOpen={activeModal === 'third'}
          onClose={() => handleModalToggle(null)}
        />

        {/* Success Modal */}
        <SuccessModal
          isOpen={activeModal === 'four'}
          onClose={() => handleModalToggle(null)}
        />

        <div className="heading-container align-items-center d-flex justify-content-between">
          <h4 className="mb-0 adminTitle">GROUP OFFERS</h4>
          <button
            className="create-group-offer-button2"
            onClick={() => handleModalToggle('create')}  // Open the modal on click
          >
            Create Group Offer
          </button>
        </div>

        <div className="group-offer-table-main">
          <table className="group-offers-table">
            <thead>
              <tr>
                <th>Group Name</th>
                <th>SKU ID</th>
                <th>List Price</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {groupOffers.length > 0 ? (
                groupOffers.map((offer, index) => (
                  <tr key={index}>
                    <td>{offer.groupName}</td>
                    <td>{offer.skuId}</td>
                    <td>{offer.listPrice}</td>
                    <td>{offer.startDate}</td>
                    <td>{offer.endDate}</td>
                    <td>
                      <div className="dropdown">
                        <button
                          className="threeDots inTable d-flex border-0 justify-content-center align-items-center"
                          type="button"
                          id="dropdownMenuButton2"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img src={threeDots} alt="threeDots" />
                        </button>
                        <ul className="dropdown-menu dropdown-menu-dark end-0 start-inherit py-0" aria-labelledby="dropdownMenuButton2">
                          <li>
                            <a
                              className="dropdown-item active"
                              onClick={() => handleModalToggle('second')}
                            >
                              Send group
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              onClick={() => handleModalToggle('edit')}
                            >
                              Edit group
                            </a>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" style={{ color: 'red', textAlign: 'center' }}>
                    No Data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default GroupOffersTable;
