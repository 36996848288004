import React from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
    return (
        <div className="footer-container">
            <div className="container d-flex justify-content-between align-items-center">
                <p className="mb-0">
                    © Copyright
                    <span className="mx-1 fw-bold">Logotitle</span>
                    All rights reserved 2024
                </p>
                <Link to="/terms" className="text-decoration-none mx-2">
                    Terms and Conditions
                </Link>
            </div>
        </div>
    );
};

export default Footer;
