import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext.js";
import AdminLoginForm from "../../components/common/AdminLoginForm.tsx";
// import Modal from "../../components/user/Modal.tsx";
import { adminlogin } from "../../utils/api/auth.js";


interface LoginPageProps {
  setCurrentPage?: (page: string) => void;
}

const LoginPage: React.FC<LoginPageProps> = ({ setCurrentPage }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    captcha: "",
    phonenumber: "",
    captchaInput: ""
  });

  type ServerMessage = {
    type: string;
    text: string;
  };

  // const [showModal, setShowModal] = useState(false);
  // const [modalMessage, setModalMessage] = useState("");
  const [serverMessage, setServerMessage] = useState<ServerMessage>({ type: '', text: '' });
  const { login } = useAuth();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { username, password } = formData;

    // if (formData.username && formData.password) {
      const response = await adminlogin(username, password);
      if(response.status == true){
        login(response.token, response.role); // Store the token and set the user as authenticated
        setServerMessage({ type: 'success', text: response.massage || 'Login successful!' });
        // navigate('/admin/group-offers');
        window.location.href = '/admin/group-offers';
      } else {
        setServerMessage({ type: 'error', text: response?.error ?? response?.message ?? 'Login failed' });
      }
  };

  // const handleCloseModal = () => setShowModal(false);

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="mt-5"></div>
          <h2 className="line-title">LOGIN</h2>
          <AdminLoginForm
            formData={formData}
            onChange={handleChange}
            onSubmit={handleSubmit}
            isSignup={false}
            serverMessage={serverMessage}
          />

          {/* <Modal show={showModal} handleClose={handleCloseModal} message={modalMessage} isTerms={false} /> */}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
