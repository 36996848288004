import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../../inc/user/Footer.tsx';
import Header from '../../inc/user/Header.tsx';
import Navbar from '../../inc/user/Navbar.tsx';

const UserLayout: React.FC = () => {
    const [currentPage, setCurrentPage] = useState<string | null>(null);

    const handleSetCurrentPage = (page: string) => {
        setCurrentPage(page); // Update the state for the current page
    };

    return (
        <div>
            <Header />
            <Navbar setCurrentPage={handleSetCurrentPage} />
            <main  style={{marginTop: '8rem'}}>
                <Outlet /> {/* Render user child routes here */}
            </main>
            <Footer />
        </div>
    );
};

export default UserLayout;
