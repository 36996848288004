import React from 'react';
import { useNavigate } from 'react-router-dom';

interface TermsAndConditionPopupProps {
  show: boolean;
  handleClose: () => void;
}

const TermsAndConditionPopup: React.FC<TermsAndConditionPopupProps> = ({ show, handleClose }) => {
  const navigate = useNavigate();

  const handleAccept = () => {
    handleClose();
    alert("integrated soon")
    // navigate("/payment-form"); // Navigate to /PaymentForm as defined in App
  };

  if (!show) return null; // Modal is not rendered when `show` is false

  return (
    <div
      className="modal fade show d-block"
      tabIndex={-1}
      role="dialog"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
        <div className="modal-content position-relative">
          <button
            type="button"
            className="btn-close position-absolute top-0 end-0 m-3"
            onClick={handleClose}
            aria-label="Close"
          ></button>

          <div className="modal-header justify-content-center">
            <h5 className="modal-title text-center" id="exampleModalLabel">Terms and Conditions</h5>
          </div>

          <div className="modal-body text-center">
            <p>Lorem Ipsum is simply dummy text of the printing...</p>
            <p>It has survived not only five centuries...</p>
          </div>
          
          <div className="modal-footer justify-content-center">
            <button
              type="button"
              className="btn btn-primary rounded-pill px-4"
              onClick={handleAccept}
            >
              Accept
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionPopup;
