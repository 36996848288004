// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.checkbox-cell {
    position: sticky;
    right: 0;
    background-color: white;
    z-index: 1;
    vertical-align: middle;
    text-align: center;
    padding-right: 5px;
}
.checkbox-cell::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -2px;
    width: 1px;
    background-color: #ddd;
    z-index: 2;
}
table {
    border-collapse: collapse;
}
td {
    border: 1px solid #ddd;
    padding: 10px;
}
.Table-heading-order{
    position: sticky;
    left: 0;
    /* background-color: white; */
    z-index: 1;
    /* vertical-align: middle; */
    /* text-align: center; */
    /* padding-right: 5px; */
}
.Table-heading-order::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: -1px;
    width: 2px;
    background-color: #ddd;
    z-index: 2;
}
`, "",{"version":3,"sources":["webpack://./src/pages/admin/OrderTracker/style.css"],"names":[],"mappings":";AACA;IACI,gBAAgB;IAChB,QAAQ;IACR,uBAAuB;IACvB,UAAU;IACV,sBAAsB;IACtB,kBAAkB;IAClB,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,kBAAkB;IAClB,MAAM;IACN,SAAS;IACT,UAAU;IACV,UAAU;IACV,sBAAsB;IACtB,UAAU;AACd;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,sBAAsB;IACtB,aAAa;AACjB;AACA;IACI,gBAAgB;IAChB,OAAO;IACP,6BAA6B;IAC7B,UAAU;IACV,4BAA4B;IAC5B,wBAAwB;IACxB,wBAAwB;AAC5B;AACA;IACI,WAAW;IACX,kBAAkB;IAClB,MAAM;IACN,SAAS;IACT,WAAW;IACX,UAAU;IACV,sBAAsB;IACtB,UAAU;AACd","sourcesContent":["\n.checkbox-cell {\n    position: sticky;\n    right: 0;\n    background-color: white;\n    z-index: 1;\n    vertical-align: middle;\n    text-align: center;\n    padding-right: 5px;\n}\n.checkbox-cell::after {\n    content: '';\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    left: -2px;\n    width: 1px;\n    background-color: #ddd;\n    z-index: 2;\n}\ntable {\n    border-collapse: collapse;\n}\ntd {\n    border: 1px solid #ddd;\n    padding: 10px;\n}\n.Table-heading-order{\n    position: sticky;\n    left: 0;\n    /* background-color: white; */\n    z-index: 1;\n    /* vertical-align: middle; */\n    /* text-align: center; */\n    /* padding-right: 5px; */\n}\n.Table-heading-order::after {\n    content: '';\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    right: -1px;\n    width: 2px;\n    background-color: #ddd;\n    z-index: 2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
