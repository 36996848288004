import React, { useState } from 'react';
import successImage from '../../../Image/success.gif';
import { ModalCreatePackage } from '../../../components/admin/OrderTracker/CreatePDFModal.tsx';
import OrderRow from '../../../components/admin/OrderTracker/OrderRow.tsx';
import { ModalPDF } from '../../../components/admin/OrderTracker/PDFModal.tsx';
import { ModalSuccess } from '../../../components/admin/OrderTracker/SuccessModal.tsx';

const Poliyogo: React.FC = () => {
    const [selectedOffer, setSelectedOffer] = useState<string>('');
    const [checkedOrders, setCheckedOrders] = useState<{ [key: string]: boolean }>({});
    const [isCheckAllChecked, setIsCheckAllChecked] = useState<boolean>(false);
    const [orderData, setOrderData] = useState<any[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showPDFModal, setShowPDFModal] = useState<boolean>(false);
    const [successModal, setSuccessModal] = useState<boolean>(false);
    const [formData, setFormData] = useState<any>({});

    const handleCheckAll = () => {
        if (!orderData.length) return;
        const newCheckedOrders = {};
        orderData.forEach((order) => {
            newCheckedOrders[order.orderNo] = !isCheckAllChecked;
        });
        setCheckedOrders(newCheckedOrders);
        setIsCheckAllChecked(!isCheckAllChecked);
    };

    const handleCheckboxChange = (orderNo: string) => {
        setCheckedOrders((prevState) => {
            const newCheckedOrders = { ...prevState };
            newCheckedOrders[orderNo] = !newCheckedOrders[orderNo];
            return newCheckedOrders;
        });
    };

    const handleOfferChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOfferValue = e.target.value;
        setSelectedOffer(selectedOfferValue);

        if (selectedOfferValue) {
            setOrderData([
                {
                    orderNo: 'Fdkjljk57',
                    buyerId: 'isdfuh97834',
                    steps: [
                        { label: 'Scan', isHighlighted: true },
                        { label: 'Sign Up', isHighlighted: true },
                        { label: 'Profile', isHighlighted: true },
                        { label: 'Order Accepted', isHighlighted: true },
                        { label: 'Payment Method', isHighlighted: false },
                        { label: 'Payment Selected', isHighlighted: false },
                        { label: 'Payment Success', isHighlighted: false },
                        { label: 'Order Generated', isHighlighted: false },
                        { label: 'SMS Triggered', isHighlighted: false },
                    ],
                },
            ]);
        } else {
            setOrderData([]);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleFormSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setShowPDFModal(true);
    };

    const rowsToDisplay = selectedOffer ? orderData : orderData.slice(0, 2);

    return (
        <div className="container">
            <div className="group-offers-container">
                <h4 className="adminTitle mb-0">View Order Tracker</h4>
                <div className="d-flex justify-content-start mb-3 mt-4">
                    <select
                        className="form-select customInput w-50"
                        value={selectedOffer}
                        onChange={handleOfferChange}
                    >
                        <option value="">Please Select Group Offer-ID</option>
                        <option value="offer1">Group Offer 1</option>
                        <option value="offer2">Group Offer 2</option>
                    </select>

                    {selectedOffer && (
                        <button className="ms-auto create-group-offer-button2">
                            <input
                                type="checkbox"
                                checked={isCheckAllChecked}
                                onChange={handleCheckAll}
                            />{' '}
                            Check All
                        </button>
                    )}
                </div>

                <div className="group-offer-table-main overflow-auto">
                    <table className="table table-bordered my-0">
                        <tbody>
                            {rowsToDisplay.map((data, index) => (
                                <OrderRow
                                    key={index}
                                    orderData={data}
                                    isChecked={checkedOrders[data.orderNo] || false}
                                    handleCheckboxChange={handleCheckboxChange}
                                    showCheckboxColumn={!!selectedOffer}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
                <button
                    className="ms-auto create-group-offer-button2 mt-3 mx-auto d-flex"
                    onClick={() => setShowModal(true)}
                >
                    Create Package
                </button>
            </div>

            <ModalCreatePackage
                show={showModal}
                onHide={() => setShowModal(false)}
                formData={formData}
                onInputChange={handleInputChange}
                onSubmit={handleFormSubmit}
            />
            <ModalPDF
                show={showPDFModal}
                onHide={() => setShowPDFModal(false)}
                formData={formData}
                onInputChange={handleInputChange}
                onGeneratePackage={() => setSuccessModal(true)}
            />
            <ModalSuccess
                show={successModal}
                onHide={() => setSuccessModal(false)}
                image={successImage}
            />
        </div>
    );
};

export default Poliyogo;
