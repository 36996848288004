import React, { useEffect, useState } from "react";
import refrsh from "../../Image/rfrsh.png";

interface FormData {
    username: string;
    password: string;
    phonenumber?: string;
    confirmpassword?: string;
    captcha: string;
    captchaInput: string;
}
type ServerMessage = {
    type: string;
    text: string;
};
interface FormProps {
    formData: FormData;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    isSignup: boolean;
    serverMessage: ServerMessage;
}

const AdminLoginForm: React.FC<FormProps> = ({ formData, onChange, onSubmit, isSignup, serverMessage }) => {
    const [captcha, setCaptcha] = useState<string>("");
    const [formErrors, setFormErrors] = useState<Partial<FormData>>({});

    useEffect(() => {
        setCaptcha(generateCaptcha());
    }, []);

    const generateCaptcha = () => {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        return Array.from({ length: 6 }, () => characters[Math.floor(Math.random() * characters.length)]).join("");
    };

    const handleRegenerateCaptcha = () => {
        setCaptcha(generateCaptcha());
    };

    const validateForm = () => {
        let errors: Partial<FormData> = {};

        // Validate username
        if (!formData.username.trim()) {
            errors.username = "Username is required";
        }

        // Validate password
        if (!formData.password || formData.password.length < 6) {
            errors.password = "Password must be at least 6 characters long";
        }

        // captcha for signup
        if (!formData.captchaInput.trim()) {
            errors.captchaInput = "captcha is required";
        }

        // Validate confirm password for signup
        if (formData.captchaInput.trim() !== captcha) {
            errors.captchaInput = "captcha do not match";
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validateForm()) {
            onSubmit(e); // Call parent onSubmit if validation passes
        }
    };

    return (
        <form className="row g-3" onSubmit={handleSubmit}>
            <div className="col-md-12">
                <input
                    type="text"
                    className={`form-control rounded-pill ${formErrors.username ? "is-invalid" : ""}`}
                    id="username"
                    value={formData.username}
                    onChange={onChange}
                    placeholder="Username"
                    required
                />
                {formErrors.username && <div className="invalid-feedback">{formErrors.username}</div>}
            </div>

            <div className="col-md-12">
                <input
                    type="password"
                    className={`form-control rounded-pill ${formErrors.password ? "is-invalid" : ""}`}
                    id="password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={onChange}
                    required
                />
                {formErrors.password && <div className="invalid-feedback">{formErrors.password}</div>}
            </div>

            <div className="col-md-6 d-flex align-items-center">
                <input
                    type="text"
                    className={`form-control rounded-pill ${formErrors.captchaInput ? "is-invalid" : ""}`}
                    id="captchaInput"
                    placeholder="Enter Captcha"
                    value={formData.captchaInput}
                    onChange={onChange}
                    required
                />
                {formErrors.captchaInput && <div className="invalid-feedback">{formErrors.captchaInput}</div>}
            </div>
            <div className="col-md-5 d-flex align-items-center mt-2">
                <input
                    type="text"
                    className="form-control rounded-pill col-md-10 captchaimage"
                    value={captcha}
                    readOnly
                    style={{ fontWeight: "bold", marginRight: "10px", textAlign: "center" }}
                />
                <button type="button" onClick={handleRegenerateCaptcha} className="btn p-0" style={{ border: "none", background: "none" }}>
                    <img src={refrsh} alt="Refresh Captcha" style={{ width: "30px", height: "30px" }} />
                </button>
            </div>
            <div className="col-md-12">
                {serverMessage.type == 'success' ? (<p className="text-success text-center">{serverMessage.text}</p>) : (serverMessage.type == 'error' ? <p className="text-danger text-center">{serverMessage.text}</p> : '') }
                
                <button type="submit" className="btn btn-primary w-100 rounded-pill">
                    {isSignup ? "Sign Up" : "Log In"}
                </button>
            </div>
        </form>
    );
};

export default AdminLoginForm;
