import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import logoWithText from '../../Image/logo-with-text.svg';

interface LinkItem {
  name: string;
  path: string;
  basePaths: string[]; // Paths to match for highlighting
}

const Sidebar: React.FC = () => {
  const location = useLocation();

  // Sidebar links with basePaths for highlighting
  const links: LinkItem[] = [
    { 
      name: 'View Group Offers', 
      path: '/admin/group-offers', 
      basePaths: ['/admin/group-offers'] 
    },
    { 
      name: 'View Buyer Sets', 
      path: '/admin/buyer-sets', 
      basePaths: ['/admin/buyer-sets', '/admin/buyer-sets/:buyer_id'] 
    },
    { 
      name: 'View SKU List', 
      path: '/admin/sku-list', 
      basePaths: ['/admin/sku-list', '/admin/sku-new'] 
    },
    { 
      name: 'View Order Tracker', 
      path: '/admin/order-tracker', 
      basePaths: ['/admin/order-tracker'] 
    },
  ];

  // Check if the current path matches any basePaths
  const isActive = (basePaths: string[]) => {
    return basePaths.some((basePath) => location.pathname.startsWith(basePath.replace(/:\w+/g, '')));
  };

  return (
    <div className="adminSideBar">
      <span className="navbar-brand">
        <Link to="/">
          <img className="logo-with-text" src={logoWithText} alt="logos" />
        </Link>
      </span>
      <ul className="list-unstyled mb-0 d-flex flex-column mt-5 gap-3 sideBarLinks">
        {links.map((link) => (
          <li key={link.name}>
            <Link
              to={link.path}
              className={`text-decoration-none ${isActive(link.basePaths) ? 'active' : ''}`}
            >
              {link.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
