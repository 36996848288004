import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import successImage from '../../../Image/success.gif'; // Ensure the image path is correct.

interface SuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ isOpen, onClose }) => {
  return (
    <Modal show={isOpen} onHide={onClose} centered className="border-0">
      <Modal.Body className="pt-0 mt-0 text-center">
        <img className="success" src={successImage} alt="Success" />
        <h6 className="text-center mb-3 theme-color">You have successfully sent your group offer.</h6>
        <Button
          type="button"
          className="w-25 mx-auto btn btn-dark d-flex justify-content-center text-center create-group-offer-button2 close-btn"
          onClick={onClose}
          aria-label="Close"
        >
          Ok
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default SuccessModal;
