import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

interface Sku {
    skuName: string;
    skuId: string;
    createdDate: string;
}

interface SKUModalProps {
    sku: Sku;
    editMode: boolean;
    onClose: () => void;
    onSaveEdit: (sku: Sku) => void;
}

const SKUModal: React.FC<SKUModalProps> = ({ sku, editMode, onClose, onSaveEdit }) => {
    const [skuState, setSkuState] = useState<Sku>(sku);

    useEffect(() => {
        setSkuState(sku); // Reset SKU state when the SKU prop changes
    }, [sku]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: keyof Sku) => {
        setSkuState({ ...skuState, [field]: e.target.value });
    };

    const handleSave = () => {
        onSaveEdit(skuState); // Save changes to SKU data
    };

    return (
        <Modal show={true} onHide={onClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{editMode ? 'Edit SKU' : 'View SKU'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col sm={12}>
                            <Form.Group controlId="skuName">
                                <Form.Label>SKU Name</Form.Label>
                                {editMode ? (
                                    <Form.Control
                                        type="text"
                                        value={skuState.skuName}
                                        onChange={(e) => handleChange(e, 'skuName')}
                                    />
                                ) : (
                                    <Form.Control type="text" value={skuState.skuName} readOnly />
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Form.Group controlId="skuId">
                                <Form.Label>SKU ID</Form.Label>
                                {editMode ? (
                                    <Form.Control
                                        type="text"
                                        value={skuState.skuId}
                                        onChange={(e) => handleChange(e, 'skuId')}
                                    />
                                ) : (
                                    <Form.Control type="text" value={skuState.skuId} readOnly />
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Form.Group controlId="createdDate">
                                <Form.Label>Created Date</Form.Label>
                                {editMode ? (
                                    <Form.Control
                                        type="text"
                                        value={skuState.createdDate}
                                        onChange={(e) => handleChange(e, 'createdDate')}
                                    />
                                ) : (
                                    <Form.Control type="text" value={skuState.createdDate} readOnly />
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {editMode ? (
                    <>
                        <Button variant="secondary" onClick={onClose}>Cancel</Button>
                        <Button variant="primary" onClick={handleSave}>Save Changes</Button>
                    </>
                ) : (
                    <Button variant="secondary" onClick={onClose}>Close</Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default SKUModal;
