import React, { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userProfile, setUserProfile] = useState({
    firstname: '',
    is_interested: '',
  });
  // Update functions
  const setProfile = (key, value) => {
    setUserProfile((prev) => ({ ...prev, [key]: value }));
};

  useEffect(() => {
    // Check if user is logged in (e.g., check localStorage for a token)
    const token = localStorage.getItem("token");
    setIsAuthenticated(!!token);
  }, []);

  const login = (token, role) => {
    localStorage.setItem("token", token);
    localStorage.setItem("role", role);
    setIsAuthenticated(true);
  };

  const handlarID = (handlarID) => {
    localStorage.setItem("handlarID", handlarID);
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, handlarID, userProfile, setProfile }} >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
