import React, { useState } from 'react';
import TermsAndConditionPopup from '../../components/user/TermsAndConditionsPopup.tsx';

const Order: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isInterested, setIsInterested] = useState<string | null>(null); // Tracks if "Yes" or "No" is selected

  const handleOpen = () => {
    // Open modal only if checkbox is checked and interest is selected
    if (isChecked && isInterested) {
      setShowModal(true);
    }
  };

  const handleClose = () => setShowModal(false);

  const handleCheckboxChange = () => {
    setIsChecked((prev) => !prev); // Toggle checkbox state
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsInterested(event.target.value); // Update selected interest
  };

  return (
    <>
      <div className="bodyScroll">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h2 className="line-title-1" style={{ width: '50%' }}>Your Unique Offer Summary</h2>
        </div>
        <div className="sdsa">
          <div className="Your-order">
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit...</p>
            <div className="Your-order-pricing">
              <div className="Pricing-left">
                <h2>Pricing</h2>
                <p>Line Items - 6</p>
                <p>Quantity - 20</p>
                <p>Size - 1GB</p>
                <p>Maintenance & Service Charge - 200/-</p>
              </div>
              <div className="Pricing-right">
                <h2>Total Cost</h2>
                <h3>₹ 3999.00/Mo</h3>
              </div>
            </div>
            <div>
              <h2 className="line-title-2"></h2>
              <div className="d-flex align-items-center">
                <input 
                  checked={isChecked} 
                  onChange={handleCheckboxChange} 
                  className="form-check-input me-2" 
                  name="acceptTC" 
                  id="acceptTC" 
                  type="checkbox" 
                />
                <label htmlFor="acceptTC" className="form-check-label">
                  I Accept All <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => setShowModal(true)}>Terms And Conditions</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '60%', margin: '0 auto', marginTop: '20px' }}>
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <label style={{ fontWeight: 'bold', color: '#5d3b8e', fontSize: '18px', marginRight: '10px' }}>
      Are You Interested?
    </label>

    <div className="form-check form-check-inline me-3">
      <input
        className="form-check-input custom-radio"
        type="radio"
        name="interest"
        id="yes"
        value="yes"
        onChange={handleRadioChange}
      />
      <label className="form-check-label" htmlFor="yes" style={{ color: '#5d3b8e' }}>Yes</label>
    </div>

    <div className="form-check form-check-inline">
      <input
        className="form-check-input custom-radio"
        type="radio"
        name="interest"
        id="no"
        value="no"
        onChange={handleRadioChange}
      />
      <label className="form-check-label" htmlFor="no" style={{ color: '#5d3b8e' }}>No</label>
    </div>
  </div>

  <button
    id="Next-Button"
    className="create-group-offer-button2"
    onClick={handleOpen}
    disabled={!isChecked || !isInterested} // Disable button if checkbox or radio isn't selected
  >
    Next
  </button>
</div>

      </div>

      {/* Modal Component */}
      <TermsAndConditionPopup show={showModal} handleClose={handleClose} />
    </>
  );
};

export default Order;
