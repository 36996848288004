import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import successImage from "../../Image/success.gif";

const PaymentSuccess: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(true);

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="d-flex justify-content-center">
      <Modal show={showModal} onHide={closeModal} centered>
        <div className="d-flex justify-content-center align-items-center mt-3">
          <img src={successImage} alt="Success" className="w-50" />
        </div>
        <h2 className="text-center mt-2">Payment Successful</h2>
        <Modal.Body>
          <p className="text-center">
            Your payment has been processed successfully.
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center align-items-center">
          <Link to="/order-success">
            <Button variant="success" onClick={closeModal}>
              OK
            </Button>
          </Link>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PaymentSuccess;
