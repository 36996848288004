import React from 'react';

const AdminFooter: React.FC = () => {
  return (
    <div className="footer-container adminFooter">
      <div className="container d-flex justify-content-between align-items-center">
        <p className="d-flex text-center mb-0 mx-auto">
          © Copyright
          <a href="#" className="text-center mx-auto">
            <span className="mx-1 theme-color fw-bold text-decoration-none">Logotitle</span>
          </a>
          All rights reserved 2024
        </p>
      </div>
    </div>
  );
};

export default AdminFooter;
