import React, { useEffect, useState } from 'react';
import BuyerSetModal from '../../../components/admin/BuyerSets/BuyerSetModal.tsx';
import BuyerSetsTable from '../../../components/admin/BuyerSets/BuyerSetsTable.tsx';
import { createBuyer, editBuyer, fetchBuyers } from '../../../utils/api/admin';
import SuccessModal from '../../../components/common/SuccessModal.tsx'; // Import the SuccessModal
import './style.css';

interface BuyerSet {
  id: number;
  buyer_set_id: string;
  buyer_set_name: string;
  buyer_counts: number;
  handler_id_check: number;
  QR: string;
  created_at: string;
}

const BuyerSets: React.FC = () => {
  const [groupOffers, setGroupOffers] = useState<BuyerSet[]>([]);
  const [activeModal, setActiveModal] = useState<string | null>(null);
  const [formData, setFormData] = useState<BuyerSet>({
    id: 0,
    buyer_set_id: '',
    created_at: '',
    buyer_set_name: '',
    buyer_counts: 0,
    handler_id_check: 0,
    QR: '',
  });
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false); // State to control the success modal
  const [isSuccess, setIsSuccess] = useState(true); // Track success or failure
  const [successMessage, setSuccessMessage] = useState<string>(''); // Custom success message

  // Function to fetch buyer sets from the server
  const fetchData = async () => {
    try {
      const buyers = await fetchBuyers();
      console.log(buyers);
      setGroupOffers(buyers);
    } catch (error) {
      console.error('Failed to fetch buyer sets:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleModalToggle = (modalName: string) => {
    setActiveModal((prev) => (prev === modalName ? null : modalName));
  };

  const handleClose = () => {
    setFormData({
      id: 0,
      buyer_set_id: '',
      created_at: '',
      buyer_set_name: '',
      handler_id_check: 0,
      buyer_counts: 0,
      QR: '',
    });
    setSelectedImage(null);
    setActiveModal(null);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleImageSelect = (imageFile: File) => {
    setSelectedImage(imageFile);
  };

  // Handle form submission for creating or editing buyer sets
  const handleFormSubmit = async (e: React.FormEvent, isEdit: boolean) => {
    e.preventDefault();

    try {
      setIsLoading(true); // Start loading state
      if (isEdit) {
        // Edit existing buyer set
        await editBuyer(
          formData.buyer_set_name,
          formData.buyer_set_id,
          formData.created_at,
          formData.id
        );
        setGroupOffers((prevOffers) =>
          prevOffers.map((offer) =>
            offer.buyer_set_id === formData.buyer_set_id
              ? { ...offer, ...formData, QR: selectedImage ? URL.createObjectURL(selectedImage) : offer.QR }
              : offer
          )
        );
        setIsSuccess(true); // Set success state
        setSuccessMessage('You have successfully edited the buyer set.'); // Custom success message for edit
        setShowSuccessModal(true); // Show success modal
      } else {
        // Create new buyer set
        const newBuyerSet = await createBuyer(
          formData.buyer_set_name,
          formData.handler_id_check,
          selectedImage
        );
        setGroupOffers((prevOffers) => [...prevOffers, { ...formData, ...newBuyerSet }]);
        setIsSuccess(true); // Set success state
        setSuccessMessage('You have successfully created the buyer set.'); // Custom success message for creation
        setShowSuccessModal(true); // Show success modal
      }
      setIsLoading(false); // Stop loading state

      await fetchData(); // Refresh the data
    } catch (error) {
      console.error('Failed to submit form:', error);
      setIsLoading(false);
      setIsSuccess(false); // Set failure state
      setSuccessMessage('There was an error while processing your request.'); // Custom failure message
      setShowSuccessModal(true); // Show failure modal
    }
    handleClose(); // Close the modal
  };

  return (
    <div className="container-fluid">
      <BuyerSetModal
        showModal={activeModal === 'create' || activeModal === 'edit'}
        handleClose={handleClose}
        formData={formData}
        handleInputChange={handleInputChange}
        handleImageSelect={handleImageSelect}
        handleFormSubmit={handleFormSubmit}
        isEdit={activeModal === 'edit'}
        isLoading={isLoading} // Pass isLoading prop to modal
      />

      <div className="heading-container align-items-center d-flex justify-content-between">
        <h4 className="mb-0 adminTitle">View Buyer Sets</h4>
        <button className="create-group-offer-button2" onClick={() => handleModalToggle('create')}>
          Create Buyer Set
        </button>
      </div>

      <BuyerSetsTable
        groupOffers={groupOffers}
        onEdit={(offer) => {
          setFormData(offer);
          handleModalToggle('edit');
        }}
      />

      {/* Success Modal */}
      <SuccessModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)} // Close success modal
        isSuccess={isSuccess} // Pass the success state to the modal
        message={successMessage} // Pass the custom success/failure message
        navigateTo={null}
      />
    </div>
  );
};

export default BuyerSets;
