import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { getAvailableBuyerList, groupofferSendgroup } from '../../../utils/api/admin';
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';

interface BuyerData {
  id: number;
  buyer_set_name: string;
}

interface SendGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
  groupName: string;
  buyerSet: BigInteger;
  SkuOption: Array<any>; // Adjust type as per actual usage
  buyerOptionId: number;  // Define buyerOptionId prop
}

const SendGroupModal: React.FC<SendGroupModalProps> = ({ isOpen, onClose, groupName, buyerSet, SkuOption, buyerOptionId }) => {
  const navigate = useNavigate();

  const [formData, setFormData] = React.useState({
    groupNamevalue: '',
    buyersetId: ''
  });

  const [errors, setErrors] = useState<Record<string, string>>({}); // To store backend validation errors
  const [isError, setIsError] = useState(false); // Track success/error
  const [isLoading, setIsLoading] = useState(false); // Track success/error
  const [buyerDatas, setBuyerData] = useState<BuyerData[]>([]);

  if (groupName) {
    formData.groupNamevalue = groupName;
  }

  const fetchData = async () => {
    try {
      const buyersetdata = await getAvailableBuyerList();
      setBuyerData(buyersetdata.data);
    } catch (error) {
      console.error('Failed to fetch buyer sets:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [groupofferSendgroup]);

  // Handle form input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // Handle form submission
  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await groupofferSendgroup(formData.groupNamevalue, formData.buyersetId, buyerOptionId);
      setIsLoading(false);
      if (response.status === "success") {
        setIsError(false); // Reset error state
        setFormData({
          groupNamevalue: '',
          buyersetId: '',
        }); // Reset form data

        Swal.fire({
          title: "Success!",
          text: response.massge,
          icon: "success",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            onClose();
            navigate('/admin/group-offers');
          }
        });
      } else {
        setIsError(true); // Reset error state
        throw new Error("Unexpected response status");
      }
    } catch (errorResponse) {
      setIsLoading(false);
      setIsError(true); // Reset error state
      const backendErrors = {};

      // Check if errorResponse.errors is defined and is an array
      if (errorResponse?.errors && Array.isArray(errorResponse.errors)) {
        errorResponse.errors.forEach((error) => {
          backendErrors[error.field] = error.message;
        });
      } else {
        console.error("No errors found or unexpected error format", errorResponse);
      }

      setErrors(backendErrors);
    }
  }

  return (
    <Modal show={isOpen} onHide={onClose} centered className="border-0">
      <Modal.Header closeButton className="border-0 pb-0">
        <Modal.Title className="fw-bold text-center">Send Group</Modal.Title>
      </Modal.Header>
      <Modal.Body className="mt-0 pt-0">
        <Row>
          <Col md={12} className="mb-3">
            <Form.Group controlId="groupName">
              <Form.Label>Group Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Group Name"
                name="groupNamevalue"
                value={formData.groupNamevalue}
                onChange={handleInputChange}
                className={`rounded-pill ${errors.groupNamevalue ? 'is-invalid' : ''}`}
              />
            </Form.Group>

            {errors.groupNamevalue && <div className="invalid-feedback d-inline-block">{errors.groupNamevalue}</div>}
          </Col>
          <Col md={12} className="mb-3">
            <Form.Group controlId="buyersetId">
              <Form.Label>Select the Buyer Set</Form.Label>
              <Form.Select
                name="buyersetId"
                value={formData.buyersetId}
                onChange={handleInputChange}
                className="rounded-pill"
              >
                <option value="">Select Buyer Sets</option>
                {buyerDatas.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.buyer_set_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            {errors.buyersetId && <div className="invalid-feedback d-inline-block">{errors.buyersetId}</div>}
          </Col>
          {!isLoading ?
            <Col md={6} className="mx-auto mt-3">
              <Button onClick={handleFormSubmit} className="w-100 create-group-offer-button2">Generate</Button>
            </Col>
            :
            <div className='row justify-content-center'>
              <div className=" col-12 spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          }
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default SendGroupModal;
