import React, { useEffect, useState } from 'react';
import ConfirmationModal from '../../../components/admin/GroupOffer/ConfirmationModal.tsx';
import CreateEditGroupOfferModal from '../../../components/admin/GroupOffer/CreateEditModal.tsx';
import GroupOffersTable from '../../../components/admin/GroupOffer/GroupOffersTable.tsx';
import SendGroupModal from '../../../components/admin/GroupOffer/SendModal.tsx';
import SuccessModal from '../../../components/common/SuccessModal.tsx';
import './style.css';
import {
	fetchgroupOffers,
	groupofferscreate,
	groupoffersupdate,
} from '../../../utils/api/admin.js';

interface GroupOfferFormData {
	name: string;
	sku_id: string;
	list_price: number | null;
	start_date: string;
	end_date: string;
	discount: string;
	discount_price: number | null;
	id: string;
}

const GroupOffersPage: React.FC = () => {
	const [activeModal, setActiveModal] = useState<string | null>(null);
	const [errors, setErrors] = useState<Record<string, string | null>>({});
	const [turnPrice, setTurnPrice] = useState<number | null>(null);
	const [discountPrice, setDiscountPrice] = useState<number | null>(null);
	const [formData, setFormData] = useState<GroupOfferFormData>({
		name: '',
		sku_id: '',
		list_price: null,
		start_date: '',
		end_date: '',
		discount: '',
		discount_price: null,
		id: '',
	});
	const [groupOffers, setGroupOffers] = useState<any[]>([]); // State to store group offers
	const [successMessage, setSuccessMessage] = useState<string>(""); // Success message state

	const handleModalToggle = (modalName: string | null) => {
		if (!modalName) {
			// Clear form data when modal closes
			setFormData({
				name: '',
				sku_id: '',
				list_price: null,
				start_date: '',
				end_date: '',
				discount: '',
				discount_price: null,
				id: '',
			});
			setTurnPrice(null);
			setDiscountPrice(null);
		}
		setActiveModal(activeModal === modalName ? null : modalName);
	};

	const fetchData = async () => {
		try {
			const buyers = await fetchgroupOffers();
			setGroupOffers(buyers);
		} catch (error) {
			console.error('Failed to fetch buyer sets:', error);
		}
	};

	const setTurnPriceValue = (total: number) => {
		setTurnPrice(total);

		setFormData((prevFormData) => {
			return {
				...prevFormData,
				discount: '',
				discount_price: null,
			};
		});
		setDiscountPrice(null);
	};

	useEffect(() => {
		if (activeModal === 'create') {
			setTurnPrice(null); // Reset turnPrice to null
			setFormData({
				...formData,
				sku_id: '',
				name: '',
				start_date: '',
				end_date: '',
				discount: '',
			}); // Reset formData
		}
		fetchData();
	}, [activeModal]);

	const handleInputChange = (
		e: React.ChangeEvent<
			HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
		>
	) => {
		const { name, value } = e.target;

		if (name === 'discount' && turnPrice !== null) {
			const discountPriceValue = turnPrice * ((100 - parseInt(value)) / 100);
			setDiscountPrice(discountPriceValue);
		}

		setFormData((prev) => ({ ...prev, [name]: value }));

		setErrors((prevErrors) => ({
			...prevErrors,
			[name]: null,
		}));
	};

	const handleCreateGroupOffer = async (e: React.FormEvent) => {
		e.preventDefault();
		try {
			const response = await groupofferscreate(
				formData.name,
				formData.start_date,
				formData.end_date,
				formData.sku_id,
				turnPrice,
				formData.discount,
				discountPrice
			);
			if (response.status === 'success') {
				await fetchData(); // Refresh data
				handleModalToggle(null); // Close modal
				setSuccessMessage('Group offer created successfully!');
				setActiveModal('success'); // Show success modal
			} else {
				throw new Error(response.message || 'Failed to create group offer');
			}
		} catch (error) {
			console.error('Error creating group offer:', error);
		}
	};

	const handleEditGroupOffer = (offer) => {
		setFormData({
			id: offer.id,
			name: offer.name,
			sku_id: offer.sku_id,
			list_price: offer.list_price,
			start_date: offer?.start_date
				? new Date(offer.start_date).toISOString().split('T')[0]
				: '',
			end_date: offer?.end_date
				? new Date(offer.end_date).toISOString().split('T')[0]
				: '',
			discount: offer.discount,
			discount_price:
				offer.list_price * ((100 - parseInt(offer.discount)) / 100),
		});
		setTurnPrice(offer.list_price);
		setDiscountPrice(
			offer.list_price * ((100 - parseInt(offer.discount)) / 100)
		);
		setActiveModal('edit');
	};

	const handleUpdateGroupOffer = async (e: React.FormEvent) => {
		e.preventDefault();
		try {
			const response = await groupoffersupdate(
				formData.name,
				formData.start_date,
				formData.end_date,
				formData.sku_id,
				turnPrice,
				formData.discount,
				discountPrice,
				formData.id
			);
			if (response.status === 'success') {
				await fetchData(); // Refresh data
				handleModalToggle(null); // Close modal
				setSuccessMessage('Group offer updated successfully!');
				setActiveModal('success'); // Show success modal
			} else {
				throw new Error(response.message || 'Failed to update group offer');
			}
		} catch (error) {
			console.error('Error updating group offer:', error);
		}
	};

	return (
		<div className="group-offers-page">
			<GroupOffersTable
				groupOffers={groupOffers}
				handleModalToggle={handleModalToggle}
				handleEditGroupOffer={handleEditGroupOffer}
				errors={errors}
			/>

			<CreateEditGroupOfferModal
				isOpen={activeModal === 'create' || activeModal === 'edit'}
				onClose={() => handleModalToggle(null)}
				onSubmit={
					activeModal === 'create'
						? handleCreateGroupOffer
						: handleUpdateGroupOffer
				}
				modalType={activeModal}
				formData={formData}
				setFormData={setFormData}
				handleInputChange={handleInputChange}
				errors={errors}
				turnPrice={turnPrice}
				setTurnPrice={setTurnPriceValue}
				discountPrice={discountPrice}
			/>

			<SuccessModal
				isOpen={activeModal === 'success'}
				onClose={() => handleModalToggle(null)}
				message={successMessage}
				isSuccess={true}
				navigateTo={null}
			/>
		</div>
	);
};

export default GroupOffersPage;
