import React, { useEffect, useState } from 'react';
import { Alert, Col, Container, Form, Modal, Row, Spinner, Table } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import BackImg from '../../../Image/back-arrow.svg';
import del from '../../../Image/delete.svg';
import { FaEye } from "react-icons/fa";
import { buyerDetails, deleteBuyerHandeler } from '../../../utils/api/admin';

// Interfaces for data types
interface BuyerHandlers {
    id: number;
    firstName: string;
    lastName: string;
    address: string;
    address2: string;
    city: string;
    state: string;
    zipCode: string;
    extRefNbr: string;
    buyer_id: number;
    QR: string;
    created_at: string;
    uniquerid: string;
    phone: string | null;
    username: string | null;
    firstname: string | null;
    lastname: string | null;
    email: string | null;
}

interface BuyerData {
    id: number;
    buyer_set_name: string;
    buyer_set_id: string;
    uniquerid: string;
    created_at: string;
    buyerHandlers: BuyerHandlers[] | null;
}

const BuyerDetails: React.FC = () => {
    const { buyer_id } = useParams<{ buyer_id: string }>();
    const [data, setData] = useState<BuyerData[]>([]);
    const [subdata, setSubdata] = useState<BuyerHandlers[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalData, setModalData] = useState<BuyerHandlers | null>(null);

    const fetchBuyerDetails = async (buyer_id: number) => {
        try {
            setLoading(true);
            const response = await buyerDetails(buyer_id);
            setData(response.buyer);
            setSubdata(response.buyerHandlers);
            setLoading(false);
        } catch (err: any) {
            setError(err.message || 'Failed to fetch buyer details');
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBuyerDetails(Number(buyer_id));
    }, []);

    const handleDelete = async (id: number): Promise<void> => {
        try {
            setLoading(true);
            await deleteBuyerHandeler(id);
            setSubdata(prevData => prevData.filter(row => row.id !== id));
            setLoading(false);
        } catch (err: any) {
            setError(err.message || 'Failed to delete buyer handler');
            setLoading(false);
        }
    };

    const openUserModel = (row: BuyerHandlers): void => {
        setModalData(row);
        setShowModal(true);
    };

    const closeModal = () => setShowModal(false);

    return (
        <Container className="p-4">
            <Link to="/admin/buyer-sets" className="text-decoration-none text-black mb-0 active">
                <h4 className="mb-0 adminTitle">
                    <img src={BackImg} alt="Back" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
                    Buyer Details
                </h4>
            </Link>
            <div className="bg-white p-2 mt-4">
                <Row className="mb-3">
                    <Col md={4}>
                        <Form.Group controlId="buyerSetName">
                            <Form.Label>Buyer Set Name</Form.Label>
                            <Form.Control
                                readOnly
                                type="text"
                                placeholder="Buyer Set Name"
                                style={{ backgroundColor: '#E8E8E8' }}
                                className="rounded-pill"
                                value={data?.buyer_set_name || ''}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="buyerSetID">
                            <Form.Label>Buyer Set ID</Form.Label>
                            <Form.Control
                                readOnly
                                type="text"
                                placeholder="Buyer Set ID"
                                className="rounded-pill"
                                style={{ backgroundColor: '#E8E8E8' }}
                                value={data?.buyer_set_id || ''}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="buyerSetCreateDate">
                            <Form.Label>Buyer Set Create Date</Form.Label>
                            <Form.Control
                                readOnly
                                type="text"
                                placeholder="Buyer Set Create Date"
                                className="rounded-pill"
                                style={{ backgroundColor: '#E8E8E8' }}
                                value={data?.created_at ? new Date(data.created_at).toLocaleDateString() : ''}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </div>

            {loading ? (
                <div className="d-flex justify-content-center">
                    <Spinner animation="border" variant="primary" />
                </div>
            ) : error ? (
                <Alert variant="danger">{error}</Alert>
            ) : (
                <Table striped bordered hover responsive className="bg-white">
                    <thead>
                        <tr>
                            <th>S.N.</th>
                            <th>Handler Address</th>
                            <th>Buyer ID</th>
                            <th>QR Code</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {subdata.length > 0 ? (
                            subdata.map((row, index) => (
                                <tr key={row.id}>
                                    <td>{index + 1}</td>
                                    <td>
                                        {`${row.address} ${row.address2 || ''} ${row.city || ''} ${row.state || ''} ${row.zipCode || ''} ${row.extRefNbr || ''}`}
                                    </td>
                                    <td>{row.uniquerid}</td>
                                    <td>
                                        <img src={row.QR} alt="QR Code" width="24" />
                                    </td>
                                    <td>
                                        <img
                                            src={del}
                                            alt="Delete"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => handleDelete(row.id)}
                                        />
                                        {row.phone && (
                                            <FaEye
                                                onClick={() => openUserModel(row)}
                                                style={{ cursor: 'pointer', color: 'royalblue', fontSize: '1.2rem', marginLeft: '.2rem' }}
                                            />
                                        )}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr className="text-center my-4">
                                <td colSpan={5}>No data</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            )}

            <Modal show={showModal} onHide={closeModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Buyer Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalData && (
                        <Table bordered>
                            <tbody>

                            {modalData.firstname && (
                                    <tr>
                                        <td>First Name</td>
                                        <td>{modalData.firstname}</td>
                                    </tr>
                                )}
                                {modalData.lastname && (
                                    <tr>
                                        <td>Last Name</td>
                                        <td>{modalData.lastname}</td>
                                    </tr>
                                )}
                                {modalData.phone && (
                                    <tr>
                                        <td>Phone</td>
                                        <td>{modalData.phone}</td>
                                    </tr>
                                )}
                                {/* {modalData.username && (
                                    <tr>
                                        <td>Username</td>
                                        <td>{modalData.username}</td>
                                    </tr>
                                )} */}
                                {modalData.email && (
                                    <tr>
                                        <td>Email</td>
                                        <td>{modalData.email}</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    )}
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default BuyerDetails;
