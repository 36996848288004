import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from '../../AuthContext.js';
import Form from "../../components/common/Form.tsx";
import LoginModal from "../../components/user/LoginModel.tsx";
import Title from "../../components/user/Title.tsx";
import { userlogin } from "../../utils/api/auth.js";

interface LoginPageProps {
    setCurrentPage?: (page: string) => void;
}

const LoginPage: React.FC<LoginPageProps> = ({ setCurrentPage }) => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        username: "",
        password: "",
        captcha: "",
    });

    const { login } = useAuth();
    const [captcha, setCaptcha] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [serverMessage, setServerMessage] = useState(null);

    useEffect(() => {
        setCaptcha(generateCaptcha());
        setCurrentPage && setCurrentPage("login");
    }, [setCurrentPage]);

    const generateCaptcha = (): string => {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        return Array.from({ length: 6 }, () => characters[Math.floor(Math.random() * characters.length)]).join("");
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleRegenerateCaptcha = () => setCaptcha(generateCaptcha());

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const { username, password, captcha: userCaptcha } = formData;
        try {
            const response = await userlogin(username, password);
            // setMessage(data.message || 'Signup successful!');
            setServerMessage({ type: response.status, text: response.massage || 'Login successful!' });

            login(response.token, 3); // Store the token and set the user as authenticated
            setFormData({ username: '', password: '', captcha: '' }); // Reset form fields on success
            setShowModal(true);
            setModalMessage("Login Successful!");
            navigate('/profile');
        } catch (error) {
            // Handle error based on error type
            if (error.response) {
                setServerMessage({ type: 'error', text: error.response });
            } else if (error.request) {
                setServerMessage({ type: 'error', text: error.message || 'An unexpected error occurred. Please try again!' });
            } else {
                setServerMessage({ type: 'error', text: error.message });
            }
            setModalMessage("Please enter valid credentials or captcha.");
        }
        // setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);

    return (
        <div className="pt-3 bodyScroll">
            <div className="container">
                <div className="row g-5 justify-content-center">
                    <div className="col-md-6 col-12">
                        <Title />
                    </div>
                    {serverMessage && (
                        <div
                            className={`message text-center ${serverMessage.type === 'error' ? 'error-message text-danger' : 'success-message text-success'}`}
                        >
                            {serverMessage.text}
                        </div>
                    )}
                    <div className="col-md-5 col-12">
                        <h2 className="line-title">LOGIN</h2>
                        <Form
                            formData={formData}
                            onChange={handleChange}
                            onSubmit={handleSubmit}
                            isSignup={false}
                        />
                        <p className="text-center mt-2">
                            Don't have an account?{" "}
                            <Link to="/" className="text-decoration-none text-white fw-bold ms-2x">
                                Sign Up
                            </Link>
                        </p>
                        <LoginModal
                            show={showModal}
                            isTerms={false}
                            handleClose={handleCloseModal}
                            message={modalMessage}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
