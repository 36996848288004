import React, { useState } from 'react';
import ConfirmationModal from '../../../components/admin/GroupOffer/ConfirmationModal.tsx';
import CreateEditGroupOfferModal from '../../../components/admin/GroupOffer/CreateEditModal.tsx';
import GroupOffersTable from '../../../components/admin/GroupOffer/GroupOffersTable.tsx';
import SendGroupModal from '../../../components/admin/GroupOffer/SendModal.tsx';
import SuccessModal from '../../../components/admin/GroupOffer/SuccessModal.tsx';
import './style.css';

const GroupOffersPage: React.FC = () => {
  const [activeModal, setActiveModal] = useState<string | null>(null);
  const [formData, setFormData] = useState<any>({
    groupName: '',
    skuId: '',
    listPrice: '',
    startDate: '',
    endDate: '',
  });
  
  const [groupOffers, setGroupOffers] = useState<any[]>([]);  // State to store group offers

  const handleModalToggle = (modalName: string | null) => {
    setActiveModal(activeModal === modalName ? null : modalName);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission to add group offer to table
  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setGroupOffers([...groupOffers, formData]);  // Add new offer to the list
    setActiveModal(null);  // Close modal after submission
  };

  return (
    <div className="group-offers-page">
      <GroupOffersTable
        groupOffers={groupOffers}  // Pass the group offers to the table
        activeModal={activeModal}
        handleModalToggle={handleModalToggle}
        formData={formData}
        handleInputChange={handleInputChange}
      />

      {/* Create/Edit Group Offer Modal */}
      <CreateEditGroupOfferModal
        isOpen={activeModal === 'create' || activeModal === 'edit'}
        onClose={() => handleModalToggle(null)}
        onSubmit={() => handleModalToggle('second')}
        modalType={'create'}
      />

      {/* Send Group Modal */}
      <SendGroupModal
        isOpen={activeModal === 'second'}
        onClose={() => handleModalToggle(null)}
        onNext={() => handleModalToggle('third')}
      />

      {/* Group Offer Confirmation Modal */}
      <ConfirmationModal
        isOpen={activeModal === 'third'}
        onClose={() => handleModalToggle(null)}
        onSend={() => handleModalToggle('four')}
        groupName={'Dummy Group'}
        buyerSet={'Dummy Buyer'}
      />

      {/* Success Modal */}
      <SuccessModal
        isOpen={activeModal === 'four'}
        onClose={() => handleModalToggle(null)}
      />
    </div>
  );
};

export default GroupOffersPage;
