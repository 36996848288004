import React from 'react';
import Delete from '../../../Image/delete.svg';

interface Item {
  id: number;
  description: string;
  quantity: number;
}

interface ItemTableProps {
  items: Item[];
  onAddItem: () => void;
  onDeleteItem: (id: number) => void;
  onInputChange: (id: number, field: string, value: string | number) => void;
}

const ItemTable: React.FC<ItemTableProps> = ({ items, onAddItem, onDeleteItem, onInputChange }) => {
  return (
    <div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>S.N.</th>
            <th>Description</th>
            <th>Size/Quantity</th>
            {items.length > 1 && <th></th>}
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>
                <input
                  type="text"
                  value={item.description}
                  onChange={(e) => onInputChange(item.id, 'description', e.target.value)}
                  placeholder="Enter description"
                  className="form-control"
                />
              </td>
              <td>
                <input
                  type="number"
                  value={item.quantity || ''}
                  onChange={(e) => onInputChange(item.id, 'quantity', e.target.value)}
                  placeholder="Enter quantity"
                  className="form-control"
                  min="0"
                />
              </td>
              {items.length > 1 && (
                <td className="d-flex justify-content-center align-items-center">
                  <button
                    className="bg-transparent border-0"
                    onClick={() => onDeleteItem(item.id)}
                  >
                    <img src={Delete} alt="delete" />
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      <button className="create-group-offer-button2 mb-3 btn-danger btn w-fit" onClick={onAddItem}>Add</button>
    </div>
  );
};

export default ItemTable;
