import React, { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import qrCode from '../../../Image/qrCode.svg'; // Update path to your QR code image

interface CreateEditModalProps {
  isOpen: boolean;
  modalType: string;
  onClose: () => void;
  onSubmit: () => void;
}

const CreateEditModal: React.FC<CreateEditModalProps> = ({ isOpen, modalType, onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    groupName: '',
    skuId: '',
    listPrice: '',
    startDate: '',
    endDate: '',
    discount: '',
    discountPrice: '',
  });

  // Handle form input changes
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> // Generalize event type to cover all form controls
  ) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(); // Pass form data to parent onCreate function
  };

  return (
    <Modal show={isOpen} onHide={onClose} size="lg" centered>
      <Modal.Header closeButton className="border-0">
        <Modal.Title className="fw-bold">CREATE GROUP OFFER</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0 mt-0">
        <Form onSubmit={handleFormSubmit}>
          <Row className="g-2 align-items-center mb-3">
            {/* Group Name */}
            <Col md={8}>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="groupName">
                    <Form.Label>Group Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Group Name"
                      name="groupName"
                      value={formData.groupName}
                      onChange={handleInputChange}
                      className="rounded-pill"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}></Col>

                {/* Start Date and End Date */}
                <Col md={6}>
                  <Form.Group controlId="startDate">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="startDate"
                      value={formData.startDate}
                      onChange={handleInputChange}
                      className="rounded-pill"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="endDate">
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="endDate"
                      value={formData.endDate}
                      onChange={handleInputChange}
                      className="rounded-pill"
                    />
                  </Form.Group>
                </Col>

                {/* SKU-ID and List Price */}
                <Col md={6}>
                  <Form.Group controlId="skuId">
                    <Form.Label>Select SKU-ID</Form.Label>
                    <Form.Select
                      name="skuId"
                      value={formData.skuId}
                      onChange={handleInputChange}
                      className="rounded-pill"
                    >
                      <option value="">Select SKU-ID</option>
                      <option value="SKU-1">SKU-1</option>
                      <option value="SKU-2">SKU-2</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="listPrice">
                    <Form.Label>List Price</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="List Price"
                      name="listPrice"
                      value={formData.listPrice}
                      onChange={handleInputChange}
                      className="rounded-pill"
                    />
                  </Form.Group>
                </Col>

                {/* Discount and Discount Price */}
                <Col md={6}>
                  <Form.Group controlId="discount">
                    <Form.Label>Discount</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Discount"
                      name="discount"
                      value={formData.discount}
                      onChange={handleInputChange}
                      className="rounded-pill"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="discountPrice">
                    <Form.Label>Discount Price</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Discount Price"
                      name="discountPrice"
                      value={formData.discountPrice}
                      onChange={handleInputChange}
                      className="rounded-pill"
                    />
                  </Form.Group>
                </Col>

                {/* Create Button */}
                <Col md={6} className="mx-auto mt-3">
                  <Button variant="dark" type="submit" className="w-100 create-group-offer-button2">
                    Create
                  </Button>
                </Col>
              </Row>
            </Col>

            {/* Image and QR Code */}
            <Col md={4} className="d-flex flex-column align-items-center">
              <div className="forQr">
                <img className="m-auto d-flex qrImg" src={qrCode} alt="QR Code" />
              </div>
              <Button variant="success" className="w-100 create-group-offer-button2">
                Generate QR Code
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateEditModal;
