import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';


const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('role');
    const location = useLocation();

    // Check if user is authenticated
    if (!token || !role) {
        // Redirect unauthenticated users to login
        return <Navigate to="/login" replace />;
    }

    // Role-based protection: Check for admin role (role === '1') for routes starting with '/admin'
    const isAdminRoute = location.pathname.startsWith('/admin');
    if (isAdminRoute && role !== '1') {
        // If not an admin, redirect to the login page or another appropriate route
        return <Navigate to="/admin/login" replace />;
    }

    // If all checks pass, render the children (protected routes)
    return <Outlet />;
};

export default ProtectedRoute;
