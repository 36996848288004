import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import masterCardImage from "../../Image/image3.png";
import visaCardImage from "../../Image/image4.png";

const PaymentMethod: React.FC = () => {
  const [selected, setSelected] = useState<"master" | "visa">("master");
  const navigate = useNavigate();

  const handlePaymentClick = (method: "master" | "visa") => {
    setSelected(method);
    navigate("/payment-form");
  };

  return (
    <div className="container text-center mt-4">
      <h2>SELECT PAYMENT METHOD</h2>
      <div className="d-flex justify-content-center mt-4">
        <label
          className={`option me-4 ${selected === "master" ? "active" : ""}`}
          onClick={() => handlePaymentClick("master")}
        >
          <input
            type="radio"
            name="payment"
            value="master"
            checked={selected === "master"}
            onChange={() => setSelected("master")}
            className="d-none"
          />
          <span>Master Card</span>
          <img src={masterCardImage} alt="Master Card" className="img-fluid" />
        </label>
        <label
          className={`option ${selected === "visa" ? "active" : ""}`}
          onClick={() => handlePaymentClick("visa")}
        >
          <input
            type="radio"
            name="payment"
            value="visa"
            checked={selected === "visa"}
            onChange={() => setSelected("visa")}
            className="d-none"
          />
          <span>Visa Card</span>
          <img src={visaCardImage} alt="Visa Card" className="img-fluid" />
        </label>
      </div>
    </div>
  );
};

export default PaymentMethod;
