import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Form from "../../components/common/Form.tsx";
import Modal from "../../components/user/Modal.tsx";
import Title from "../../components/user/Title.tsx";
import { usersignup } from "../../utils/api/auth";
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../AuthContext.js';
import SuccessModal from "../../components/common/SuccessModal.tsx";
interface SignupPageProps {
  setCurrentPage: (page: string) => void;
}

interface FormData {
  username: string;
  phonenumber: string;
  password: string;
  confirmpassword: string;
  captcha: string;
  captchaInput: string;
}

interface ServerMessage {
  type: "success" | "error";
  text: string;
}

const SignupPage: React.FC<SignupPageProps> = ({ setCurrentPage }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { handlarID } = useAuth();
  const handlar = searchParams.get('handlarID');

  useEffect(() => {
    if (handlar) {
      handlarID(handlar);
    } else {
      navigate("/login");
    }
  }, []);


  const [formData, setFormData] = useState<FormData>({
    username: "",
    phonenumber: "",
    password: "",
    confirmpassword: "",
    captcha: "",
    captchaInput: "",
  });

  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>("");
  const [navigateTo, seteNavigateTo] = useState<string|null>(null);
  const [serverMessage, setServerMessage] = useState<ServerMessage | null>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setServerMessage(null); // Clear previous messages
    const handlarID = localStorage.getItem('handlarID');
    if (handlarID == null) {
      alert("Invalid QR data")
    }

    const { username, phonenumber, password, confirmpassword, captcha, captchaInput } = formData;

    if (username && phonenumber && password && password === confirmpassword) {
      try {
        const response = await usersignup(
          username,
          password,
          phonenumber,
          confirmpassword,
          captchaInput,
          handlarID
        );

        setFormData({
          username: "",
          phonenumber: "",
          password: "",
          confirmpassword: "",
          captcha: "",
          captchaInput: "",
        });

        
        localStorage.removeItem("mytime");
        
        if(response.status === "success"){
          setShowModal(true);
          setIsSuccess(true);
          seteNavigateTo('/login');
          setModalMessage("User signed up successfully")
        }
        // navigate("/login");
      } catch (error: any) {
        const errorMessage =
          error.response?.data?.message ||
          error.message ||
          "An unexpected error occurred. Please try again!";
        setServerMessage({ type: "error", text: errorMessage });
      }
    } else {
      setModalMessage("Please fill all fields correctly.");
      setShowModal(true);
    }
  };

  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <div className="bodyScroll pt-3">
        <div className="container">
          <div className="row g-5 justify-content-center">
            <div className="col-md-6 col-12">
              <Title />
            </div>
            <div className="col-md-5 col-12">
              <h2 className="line-title">SIGN UP</h2>

              {serverMessage && (
                <div
                  className= {`message text-center mb-3 ${serverMessage.type === "error"
                    ? "error-message text-danger"
                    : "success-message text-success"
                    }`}
                >
                  {serverMessage.text}
                </div>
              )}

              <Form
                formData={formData}
                onChange={handleChange}
                onSubmit={handleSubmit}
                isSignup={true}
              />

              <p className="mt-2 text-center">
                <b>Already have an account? </b>
                <Link
                  to="/login"
                  className="text-decoration-none text-white fw-bold ms-2"
                >
                  Login
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <SuccessModal
          isOpen={showModal}
          onClose={handleCloseModal}
          isSuccess={isSuccess}
          message={modalMessage}
          navigateTo={navigateTo}
        />
      )}
    </>
  );
};

export default SignupPage;
