import React from 'react';
import { Link } from 'react-router-dom';
import PaymentMethod from '../../components/user/PaymentMethod.tsx';

const PaymentForm: React.FC = () => {
  return (
    <>
      <div className='bodyScroll'>
        <PaymentMethod />
        <div className="container d-flex justify-content-center align-items-center">
          <div className="">
            <h3 className="text-center mb-4 mt-5">PAYMENT DETAILS</h3>
            <form>
              <div className="row mb-3">
                <div className="col">
                  <input
                    type="text"
                    className="form-control rounded-pill"
                    placeholder="Card Holder Name"
                    required
                  />
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control rounded-pill"
                    placeholder="Card Number"
                    required
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col">
                  <input
                    type="text"
                    className="form-control rounded-pill"
                    placeholder="Expiry Date"
                    onFocus={(e) => (e.target.type = 'month')}
                    required
                  />
                </div>
                <div className="col">
                  <input
                    type="password"
                    className="form-control rounded-pill"
                    placeholder="CVV"
                    required
                  />
                </div>
              </div>

              <div className="text-center">
                <Link to="/payment-success">
                  <button type="submit" className="btn btn-dark rounded-pill px-5">
                    Submit
                  </button>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentForm;
