import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserDetails, updateProfile } from '../../utils/api/user';
import SuccessModal from '../../components/common/SuccessModal.tsx';
import { useAuth } from '../../AuthContext.js';

interface TimeBoxProps {
  value: number;
  label: string;
}

const TimeBox: React.FC<TimeBoxProps> = ({ value, label }) => (
  <div className="time-box text-center">
    <span className="d-block fw-bold timeBox">{value}</span>
    <small className="themeColor2">{label}</small>
  </div>
);

const Profile: React.FC = () => {
  const navigate = useNavigate();
  const { setProfile } = useAuth();

  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    fulladdress: '',
  });

  const [isConfirmChecked, setIsConfirmChecked] = useState(false);
  const [isAcceptChecked, setIsAcceptChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [start_date, setStartDate] = useState('');
  const [end_date, setEndDate] = useState('');
  const [difference, setDifference] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
  });

  const fetchUserDetails = async () => {
    try {
      const userDetails = await getUserDetails();
      if(userDetails.firstname){
        setProfile('firstname', userDetails.firstname);
        setProfile('is_interested', userDetails.is_interested);
        navigate('/order');
      }
      
      // Set the group offers dates with fallback to current date
      const groupOffersStartDate = userDetails.group_offers_start_date || new Date().toISOString();
      const groupOffersEndDate = userDetails.group_offers_end_date || new Date().toISOString();

      localStorage.setItem('group_offers_start_date', groupOffersStartDate);
      localStorage.setItem('group_offers_end_date', groupOffersEndDate);

      setStartDate(groupOffersStartDate);
      setEndDate(groupOffersEndDate);

      setFormData({
        firstname: userDetails.firstname || '',
        lastname: userDetails.lastname || '',
        email: userDetails.email || '',
        phone: userDetails.phone || '',
        fulladdress: `${userDetails.address || ''} ${userDetails.address2 || ''} ${userDetails.city || ''} ${userDetails.state || ''} ${userDetails.zipCode || ''}`.trim(),
      });
    } catch (error) {
      console.error('Failed to fetch user details:', error);
      setErrorMessage('Failed to fetch your details. Please try again later.');
    }
  };
  useEffect(() => {
    fetchUserDetails();
    const intervalId = setInterval(calculateTimeLeft, 3000);
    return () => clearInterval(intervalId); // Clean up the interval on unmount
  }, []);

  const calculateTimeLeft = () => {
    const now = new Date(); // Current date and time
    const end = new Date(localStorage.getItem('group_offers_end_date') || new Date().toISOString()); // Target end date

    const timeDifference = end - now; // Difference in milliseconds

    if (timeDifference > 0) {
      // Convert milliseconds to days, hours, and minutes
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Correct multiplication
      const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)); // Correct multiplication
      const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60)); // Correct multiplication

      setDifference({ days, hours, minutes });
    } else {
      // Time has expired
      setDifference({ days: 0, hours: 0, minutes: 0 });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    if (name === 'confirmMessages') {
      setIsConfirmChecked(checked);
    } else if (name === 'acceptMessages') {
      setIsAcceptChecked(checked);
    }
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isConfirmChecked || !isAcceptChecked) {
      setErrorMessage('You must confirm your postal address and accept to receive text messages.');
      return;
    }

    try {
      const { firstname, lastname, email, phone } = formData;
      await updateProfile(firstname, lastname, email, phone);
      if(firstname) setProfile('firstname', firstname);
      
      setErrorMessage(null);
      setIsModalOpen(true); // Show success modal
    } catch (error: any) {
      setErrorMessage(error?.message || 'An unexpected error occurred.');
    }
  };

  return (
    <div className="bodyScroll">
      <div className="container-fluid d-flex align-items-center justify-content-center">
        <div className="row justify-content-center w-100 pt-3">
          <div className="col-md-5 text-start">
            <h4 className="mb-0 sm-center">My Profile</h4>
          </div>

          <div className="col-md-5 text-end text-sm-center justify-content-center">
            <h6 className="mb-0 fs-6 text">Group Offer Registration Ends By</h6>
            <div className="countdown d-flex gap-2 mt-2 bxss">
              <TimeBox value={difference.days} label="Days" />
              <TimeBox value={difference.hours} label="Hours" />
              <TimeBox value={difference.minutes} label="Minutes" />
            </div>
          </div>

          <div className="col-12 mt-4">
            <form className="row g-3 justify-content-center" onSubmit={handleFormSubmit}>
              {errorMessage && <p className="text-center text-danger m-0">{errorMessage}</p>}
              <div className="col-md-5">
                <input
                  type="text"
                  className="form-control rounded-pill"
                  placeholder="First Name"
                  name="firstname"
                  value={formData.firstname}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="col-md-5">
                <input
                  type="text"
                  className="form-control rounded-pill"
                  placeholder="Last Name"
                  name="lastname"
                  value={formData.lastname}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="col-md-5">
                <input
                  type="email"
                  className="form-control rounded-pill"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="col-md-5">
                <input
                  type="tel"
                  className="form-control rounded-pill"
                  placeholder="Phone Number"
                  name="phone"
                  value={formData.phone}
                  readOnly
                  style={{ backgroundColor: '#D8D8D8' }}
                />
              </div>
              <div className="col-md-10">
                <input
                  type="text"
                  className="form-control rounded-pill"
                  placeholder="Full Address"
                  name="fulladdress"
                  value={formData.fulladdress}
                  readOnly
                  style={{ backgroundColor: '#D8D8D8' }}
                />
              </div>
              <div className="col-md-10">
                <div className="form-check d-flex align-items-center">
                  <input
                    className="form-check-input me-2"
                    name="confirmMessages"
                    id="confirmMessages"
                    type="checkbox"
                    checked={isConfirmChecked}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="confirmMessages" className="form-check-label">
                    I confirm my postal address.
                  </label>
                </div>
              </div>
              <div className="col-md-10">
                <div className="form-check d-flex align-items-center">
                  <input
                    className="form-check-input me-2"
                    name="acceptMessages"
                    id="acceptMessages"
                    type="checkbox"
                    checked={isAcceptChecked}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="acceptMessages" className="form-check-label">
                  I accept to receive text messages. Message and data rates may apply.
                  </label>
                </div>
              </div>
              <div className="col-md-4">
                <button type="submit" className="btn btn-dark w-100 rounded-pill">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Success Modal */}
      <SuccessModal
        isOpen={isModalOpen}
        onClose={() => 
          {setIsModalOpen(false);
          navigate('/order');
        }}
        isSuccess={true}
        message="Profile updated successfully."
        navigateTo={null}
      />
    </div>
  );
};

export default Profile;
