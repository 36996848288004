import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserDetails, updateProfile } from '../../utils/api/user';

// TimeBox Component
interface TimeBoxProps {
  value: string;
  label: string;
}

const TimeBox: React.FC<TimeBoxProps> = ({ value, label }) => (
  <div className="time-box text-center">
    <span className="d-block fw-bold timeBox">{value}</span>
    <small className="themeColor2">{label}</small>
  </div>
);

const Profile: React.FC = () => {
  const navigate = useNavigate();

  // State to hold form inputs
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    fulladdress: '',
  });

  const [isConfirmChecked, setIsConfirmChecked] = useState(false);
  const [isAcceptChecked, setIsAcceptChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // Fetch user details on component mount
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const userDetails = await getUserDetails();
        setFormData({
          firstname: userDetails.firstname || '',
          lastname: userDetails.lastname || '',
          email: userDetails.email || '',
          phone: userDetails.phone || '',
          fulladdress: userDetails.fulladdress || '',
        });
      } catch (error) {
        console.error('Failed to fetch user details:', error);
      }
    };

    fetchUserDetails();
  }, []);

  // Handle input change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle checkbox change
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    if (name === 'confirmMessages') {
      setIsConfirmChecked(checked);
    } else if (name === 'acceptMessages') {
      setIsAcceptChecked(checked);
    }
  };

  // Handle form submission
  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let validationError = '';
    if (!isConfirmChecked || !isAcceptChecked) {
      validationError = 'You must confirm your postal address and accept to receive text messages.';
    }

    if (validationError) {
      setErrorMessage(validationError);
      return;
    }

    try {
      const { firstname, lastname, email, phone } = formData;
      setErrorMessage(null); // Clear previous errors
      await updateProfile(firstname, lastname, email, phone);
      alert('Profile updated successfully!');
      navigate('/order');
    } catch (error: any) {
      setErrorMessage(error?.message || 'An unexpected error occurred.');
    }
  };

  return (
    <div className="bodyScroll">
      <div className="container-fluid d-flex align-items-center justify-content-center">
        <div className="row w-75 pt-3">
          {/* Left Section */}
          <div className="col-md-6 text-start">
            <h4 className="mb-0">My Profile</h4>
          </div>

          {/* Right Section */}
          <div className="col-md-6 text-end">
            <h6 className="mb-0 fs-6 text">Group Offer Registration Ends By</h6>
            <div className="countdown d-flex justify-content-end gap-2 mt-2">
              <TimeBox value="07" label="Days" />
              <TimeBox value="11" label="Hours" />
              <TimeBox value="01" label="Minutes" />
            </div>
          </div>

          {/* Form Section */}
          <div className="col-12 mt-4">
            <form className="row g-3 justify-content-center" onSubmit={handleFormSubmit}>
              {/* Error Message */}
              <div className="col-12">
                {errorMessage && (
                  <p className="text-center text-danger m-0">{errorMessage}</p>
                )}
              </div>
              <div className="col-md-5">
                <input
                  type="text"
                  className="form-control rounded-pill"
                  placeholder="First Name"
                  name="firstname"
                  value={formData.firstname}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="col-md-5">
                <input
                  type="text"
                  className="form-control rounded-pill"
                  placeholder="Last Name"
                  name="lastname"
                  value={formData.lastname}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="col-md-5">
                <input
                  type="email"
                  className="form-control rounded-pill"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="col-md-5">
                <input
                  type="tel"
                  className="form-control rounded-pill"
                  placeholder="Phone Number"
                  name="phone"
                  value={formData.phone}
                  readOnly
                />
              </div>
              <div className="col-md-10">
                <input
                  type="text"
                  className="form-control rounded-pill"
                  placeholder="Full Address"
                  name="fulladdress"
                  value={formData.fulladdress}
                  readOnly
                />
              </div>
              <div className="col-md-10">
                <div className="form-check d-flex align-items-center">
                  <input
                    className="form-check-input me-2"
                    name="confirmMessages"
                    id="confirmMessages"
                    type="checkbox"
                    checked={isConfirmChecked}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="confirmMessages" className="form-check-label">
                    I confirm my postal address.
                  </label>
                </div>
              </div>
              <div className="col-md-10">
                <div className="form-check d-flex align-items-center">
                  <input
                    className="form-check-input me-2"
                    name="acceptMessages"
                    id="acceptMessages"
                    type="checkbox"
                    checked={isAcceptChecked}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="acceptMessages" className="form-check-label">
                    I Accept To Receive Text Messages. Message And Data Rates May Apply.
                  </label>
                </div>
              </div>
              <div className="col-md-4">
                <button type="submit" className="btn btn-dark w-100 rounded-pill">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
