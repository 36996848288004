import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL; // Replace with your backend API URL

// Create an Axios instance with global configuration
const api = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});
// Interceptor to add the Bearer token to each request
api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token'); // Retrieve the token from local storage or another source
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

export const getUserDetails = async () => {
    try {
        const response = await api.get('/users/dashboard');
        return response?.data?.data;
    } catch (error) {
        throw error.response?.data || 'Failed to fetch data';
    }
};
export const updateProfile = async (firstname, lastname, email, phone) => {
    try {
        const response = await api.post('/users/update', { firstname, lastname, email, phone });
        return response?.data;
    } catch (error) {
        throw error.response?.data || 'Failed to fetch data';
    }
};
