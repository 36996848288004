import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ItemTable from '../../../components/admin/SKUList/ItemTable.tsx';
import RecurringItemTable from '../../../components/admin/SKUList/RecurringItemTable.tsx';
import BackImg from '../../../Image/back-arrow.svg';
import { createSku } from '../../../utils/api/admin';
import './style.css';

const CreateSKUForm: React.FC = () => {
  const [items, setItems] = useState([{ id: 1, description: '', quantity: 0 }]);
  const [recurringItems, setRecurringItems] = useState([{ id: 1, description: '', quantity: 0, price: 0 }]);
  const [skuName, setSkuName] = useState('');
  const [skuDescription, setSkuDescription] = useState('');
  const [activeLink, setActiveLink] = useState('AdminSKUList');
  const totalTurnKeyPrice = 2100;
  const navigate = useNavigate();

  const handleAddItem = () => {
    const newItem = { id: Date.now(), description: '', quantity: 0 };
    setItems((prevItems) => [...prevItems, newItem]);
  };

  const handleDeleteItem = (id: number) => {
    setItems((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  const handleLinkClick = (linkName: string) => {
    setActiveLink(linkName);
  };

  const handleInputChange = (id: number, field: string, value: string | number) => {
    setItems((prevItems) => 
      prevItems.map((item) => (item.id === id ? { ...item, [field]: value } : item))
    );
  };

  const handleRecurringChange = (id: number, field: string, value: string | number) => {
    setRecurringItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? { ...item, [field]: field === 'quantity' || field === 'price' ? parseInt(value.toString()) || 0 : value }
          : item
      )
    );
  };

  const handleSubmit = async () => {
    const itemData = items.map(({ description, quantity }) => ({ description, size: quantity }));
    const recurringData = recurringItems.map(({ description, quantity, price }) => ({
      description,
      size: quantity,
      price,
      total_price: quantity * price,
    }));

    const skuData = {
      name: skuName,
      description: skuDescription,
      total: totalTurnKeyPrice,
      item: itemData,
      monthly_recurring_charge: recurringData[0] || {},
    };

    try {
      const response = await createSku(
        skuData.name,
        skuData.description,
        skuData.total,
        skuData.item,
        skuData.monthly_recurring_charge
      );
      // console.log('SKU Created:', response);
      navigate('/admin/sku-list');
      // Optionally, redirect or clear form on success
    } catch (error) {
      console.error('Failed to create SKU:', error);
    }
  };

  return (
    <div className="container">
      <div className="group-offers-container">
        <Link
          to="/admin/sku-list"
          onClick={() => handleLinkClick('AdminSKUList')}
          className={`text-decoration-none text-black mb-0 ${activeLink === 'AdminSKUList' ? 'active' : ''}`}
        >
          <h4 className="mb-0 adminTitle">
            <img src={BackImg} alt="Back" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
            Create New SKU
          </h4>
        </Link>

        <div className="card mt-4 p-3">
          <div className="mb-3">
            <label htmlFor="skuName" className="form-label">SKU Name</label>
            <input
              type="text"
              className="form-control"
              id="skuName"
              value={skuName}
              onChange={(e) => setSkuName(e.target.value)}
              placeholder="SKU Name"
            />
          </div>

          <div className="mb-3">
            <label htmlFor="skuDescription" className="form-label">SKU Description</label>
            <textarea
              className="form-control"
              id="skuDescription"
              rows={3}
              value={skuDescription}
              onChange={(e) => setSkuDescription(e.target.value)}
              placeholder="SKU Description"
            />
          </div>

          <ItemTable
            items={items}
            onAddItem={handleAddItem}
            onDeleteItem={handleDeleteItem}
            onInputChange={handleInputChange}
          />

          <div className="d-flex justify-content-between">
            <h5>Total Turn Key Price</h5>
            <h5 className="text-success">${totalTurnKeyPrice}</h5>
          </div>

          <h5 className="mt-4">Monthly Recurring Charge</h5>
          <RecurringItemTable
            recurringItems={recurringItems}
            onRecurringChange={handleRecurringChange}
          />

          <button onClick={handleSubmit} className="create-group-offer-button2 w-fit">Generate</button>
        </div>
      </div>
    </div>
  );
};

export default CreateSKUForm;
