import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { getSkuById, updateSku } from '../../../utils/api/admin'; // Fetch and update functions
import BackImg from '../../../Image/back-arrow.svg';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import SuccessModal from '../../../components/common/SuccessModal.tsx';
import { FaTrash } from 'react-icons/fa';

interface Item {
  id: number;
  sku_id: number;
  description: string;
  size: string;
  created_at: string;
  updated_at: string | null;
}

interface MonthlyRecurringCharge {
  id: number;
  sku_id: number;
  description: string;
  size: number;
  price: number;
  total_price: number;
  created_at: string;
  updated_at: string | null;
}

interface SkuDetails {
  sku: {
    id: number;
    name: string;
    description: string;
    total: number;
    turn_key_price: number;
    created_at: string;
    updated_at: string | null;
  };
  items: Item[];
  monthlyRecurringCharges: MonthlyRecurringCharge[];
}

// Yup Validation Schema
const validationSchema = Yup.object({
  skuName: Yup.string().required('SKU Name is required').min(3, 'Name must be at least 3 characters long'),
  skuDescription: Yup.string().required('SKU Description is required').min(10, 'Description must be at least 10 characters long'),
  turnKeyPrice: Yup.number().required('Turnkey price is required').positive().min(0, 'Turnkey price must be greater than or equal to 0'),
  items: Yup.array().of(
    Yup.object({
      description: Yup.string().required('Item description is required').min(3, 'Item description must be at least 3 characters long'),
      size: Yup.number().required('Item size is required').positive().min(1, 'Item size must be greater than or equal to 1')
    })
  ).required('Items are required').min(1, 'There must be at least one item'),
  recurringItems: Yup.array().of(
    Yup.object({
      description: Yup.string().required('Charge description is required').min(3, 'Charge description must be at least 3 characters long'),
      size: Yup.number().required('Charge size is required').positive().min(1, 'Charge size must be greater than or equal to 1'),
      price: Yup.number().required('Price is required').positive().min(0, 'Price must be greater than or equal to 0'),
      total_price: Yup.number().required('Total price is required').positive().min(0, 'Total price must be greater than or equal to 0')
    })
  ).required('Monthly recurring charges are required')
});

const SKUEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [skuDetails, setSkuDetails] = useState<SkuDetails | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  //modal
const [isOpen, setIsOpen] = useState(false); 
const [isSuccess, setIsSuccess] = useState(true); 
const [message, setMessage] = useState('SKU updated successfully')
const [toNavigate, setToNavigate] = useState<string | null>(null)
//modal
  useEffect(() => {
    const fetchSku = async () => {
      try {
        if (!id) throw new Error('SKU ID is missing');
        const response = await getSkuById(id);
        setSkuDetails(response);
      } catch (err) {
        setError('Failed to fetch SKU details. Please try again.');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchSku();
  }, [id]);

  const handleSave = async (values: any) => {
    const skuData = {
      name: values.skuName,
      description: values.skuDescription,
      total: values.recurringItems.reduce((acc: number, item: any) => acc + (item.size * item.price), 0),
      item: values.items,
      monthly_recurring_charge: values.recurringItems.length === 1 ? values.recurringItems[0] : {},  // Send the first item or empty object
      turn_key_price: values.turnKeyPrice,
    };


    try {
      await updateSku(id, skuData);
      setToNavigate('/admin/sku-list');
      setIsOpen(true)
      
      // navigate(`/admin/sku-list`);  
    } catch (error) {
      console.error('Failed to update SKU:', error);
    }
  };

  if (loading) return <div>Loading...</div>;

  if (error) return <div>{error}</div>;

  if (!skuDetails) return <div>SKU data not available</div>;

  return (
    <div className="container">
      <div className="group-offers-container">
        <Link to="/admin/sku-list" className="text-decoration-none text-black mb-0">
          <h4 className="mb-0 adminTitle">
            <img src={BackImg} alt="Back" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
            Edit SKU
          </h4>
        </Link>

        <Formik
          initialValues={{
            skuName: skuDetails.sku.name,
            skuDescription: skuDetails.sku.description,
            turnKeyPrice: skuDetails.sku.turn_key_price,
            items: skuDetails.items,
            recurringItems: skuDetails.monthlyRecurringCharges
          }}
          validationSchema={validationSchema}
          onSubmit={handleSave}
        >
          {({ values, handleChange, handleBlur, setFieldValue }) => (
            <Form>
              <div className="card mt-4 p-3">
                <div className="mb-3">
                  <label htmlFor="skuName" className="form-label">SKU Name</label>
                  <Field
                    type="text"
                    className="form-control"
                    id="skuName"
                    name="skuName"
                    value={values.skuName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage name="skuName" component="div" className="text-danger" />
                </div>

                <div className="mb-3">
                  <label htmlFor="skuDescription" className="form-label">SKU Description</label>
                  <Field
                    as="textarea"
                    className="form-control"
                    id="skuDescription"
                    name="skuDescription"
                    rows={3}
                    value={values.skuDescription}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage name="skuDescription" component="div" className="text-danger" />
                </div>

                <div className="table-container">
                  <h5>Items</h5>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>S.N.</th>
                        <th>Description</th>
                        <th>Quantity</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {values.items.map((item: Item, index: number) => (
                        <tr key={item.id}>
                          <td>{index + 1}</td>
                          <td>
                            <Field
                              type="text"
                              name={`items[${index}].description`}
                              value={item.description}
                              onChange={handleChange}
                              className="form-control"
                            />
                          </td>
                          <td>
                            <Field
                              type="number"
                              name={`items[${index}].size`}
                              value={item.size}
                              onChange={handleChange}
                              className="form-control"
                            />
                          </td>
                          <td>
                          <button
															type="button"
															className="btn btn-danger"
															onClick={() => {
																const newItems = values.items.filter(
																	(_, i) => i !== index
																);
																setFieldValue('items', newItems);
															}}
														>
															Delete
														</button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <button
										type="button"
										className="btn btn-primary"
										onClick={() => {
											const newItem = {
												id: Date.now(),
												description: '',
												quantity: 0,
												price: 0,
											};
											setFieldValue('items', [...values.items, newItem]);
										}}
									>
										Add Item
									</button>
                </div>

                <div className="d-flex justify-content-between mt-4">
                  <h5>Total Turn Key Price</h5>
                  <Field
                    type="number"
                    className="form-control"
                    name="turnKeyPrice"
                    value={values.turnKeyPrice}
                    onChange={handleChange}
                  />
                  <ErrorMessage name="turnKeyPrice" component="div" className="text-danger" />
                </div>

                <h5 className="mt-4">Monthly Recurring Charge</h5>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>S.N.</th>
                      <th>Description</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Total Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {values.recurringItems.map((item: MonthlyRecurringCharge, index: number) => (
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>
                          <Field
                            type="text"
                            name={`recurringItems[${index}].description`}
                            value={item.description}
                            onChange={handleChange}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <Field
                            type="number"
                            name={`recurringItems[${index}].size`}
                            value={item.size}
                            onChange={handleChange}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <Field
                            type="number"
                            name={`recurringItems[${index}].price`}
                            value={item.price}
                            onChange={handleChange}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <Field
                            type="number"
                            name={`recurringItems[${index}].total_price`}
                            value={item.size * item.price}
                            className="form-control"
                            readOnly
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <button type="submit" className="btn btn-success w-fit">
                  Save Changes
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <SuccessModal
      navigateTo={toNavigate}
     isOpen={isOpen}
     onClose={() => setIsOpen(false)}
     isSuccess={isSuccess}
     message={message}
/>

    </div>
  );
};

export default SKUEdit;
