import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL; // Replace with your backend API URL

// Create an Axios instance with global configuration
const api = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});
// Interceptor to add the Bearer token to each request
api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token'); // Retrieve the token from local storage or another source
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});


export const fetchBuyers = async () => {
    try {
        const response = await api.get('/admin/buyer/index');
        return response?.data?.data;
    } catch (error) {
        throw error.response?.data || 'Failed to fetch data';
    }
};

export const createBuyer = async (buyer_set_name, handler_id_check,buyercsv) => {
    try {
        const response = await api.post('/admin/buyer/create', { buyer_set_name, handler_id_check, buyercsv });
        return response?.data?.data;
    } catch (error) {
        throw error.response?.data || 'Failed to fetch data';
    }
};

export const editBuyer = async (buyer_set_name, buyer_set_id,buyer_set_create_date, id) => {
    try {
        const response = await api.post('/admin/buyer/update', { buyer_set_name, buyer_set_id, buyer_set_create_date, id });
        return response?.data?.data;
    } catch (error) {
        throw error.response?.data || 'Failed to fetch data';
    }
};

export const buyerDetails = async (id) => {
    try {
        const response = await api.post('/admin/buyerHandler/by/buyerid', { id });
        return response?.data?.data;
    } catch (error) {
        throw error.response?.data || 'Failed to fetch data';
    }
};
export const deleteBuyerHandeler = async (id) => {
    try {
        const response = await api.post('/admin/delete/buyerHandler', { id });
        return response?.data?.data;
    } catch (error) {
        throw error.response?.data || 'Failed to fetch data';
    }
};

export const fetchSku = async () => {
    try {
        const response = await api.get('/admin/sku/index');
        return response?.data?.data;
    } catch (error) {
        throw error.response?.data || 'Failed to fetch data';
    }
};

export const createSku = async (name, description,total, item, monthly_recurring_charge) => {
    try {
        const response = await api.post('/admin/sku/create', {name, description,total, item, monthly_recurring_charge});
        return response?.data?.data;
    } catch (error) {
        throw error.response?.data || 'Failed to fetch data';
    }
};

export const deleteSku = async (id) => {
    try {
        const response = await api.post('/admin/sku/delete', {id});
        return response?.data;
    } catch (error) {
        throw error.response?.data || 'Failed to fetch data';
    }
};

export default api;
