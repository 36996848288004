import React, { useEffect, useState } from 'react';
import successImage from '../../../Image/success.gif';
import { ModalCreatePackage } from '../../../components/admin/OrderTracker/CreatePDFModal.tsx';
import OrderRow from '../../../components/admin/OrderTracker/OrderRow.tsx';
import { ModalPDF } from '../../../components/admin/OrderTracker/PDFModal.tsx';
import { ModalSuccess } from '../../../components/admin/OrderTracker/SuccessModal.tsx';
import { fetchgroupOffers, buyerSetDetails } from '../../../utils/api/admin.js';
import './style.css';
import { Col, Form, Row } from 'react-bootstrap';

interface Offer {
	id: number;
	name: string;
	start_date: string;
	end_date: string;
	sku_id: number;
	list_price: number;
	discount: number;
	discount_price: number;
	QR: string;
	buyersetId: number;
	created_at: string;
	updated_at: string | null;
	sku_name: string | null;
}

const Poliyogo: React.FC = () => {
	const [selectedOffer, setSelectedOffer] = useState<Offer | null>(null); // Change to store full offer
	const [checkedOrders, setCheckedOrders] = useState<{
		[key: string]: boolean;
	}>({});
	const [isCheckAllChecked, setIsCheckAllChecked] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [showPDFModal, setShowPDFModal] = useState<boolean>(false);
	const [successModal, setSuccessModal] = useState<boolean>(false);
	const [formData, setFormData] = useState<any>({});
	const [groupOffers, setGroupOffers] = useState<any[]>([]); // State to store group offers

	const [orderData, setOrderData] = useState<any[]>([]);
	const handleOfferChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
		const selectedOfferId = e.target.value;
		const selectedOfferValue = groupOffers.find(
			(offer) => offer.id.toString() === selectedOfferId
		);
		setSelectedOffer(selectedOfferValue || null); // Store the entire offer object
		if (selectedOfferValue) {
			let customers = [];
			let buyeritem;
			let qrScanned: boolean;
			let isRegistered: boolean;
			let haveProfile: boolean;
			let isInterested: boolean;
			let userInterest: string = 'Interest - Pending';
			try {
				const buyers = await buyerSetDetails(selectedOfferValue.buyersetId);
				let buyer_set = buyers.buyer;
				for (let buyerId in buyers.buyerHandlers) {
					const buyer = buyers.buyerHandlers[buyerId]; // Get the actual buyer object
					qrScanned = buyer.qr_scanned == '1';
					isRegistered = qrScanned && buyer.username;
					haveProfile = isRegistered && buyer.firstname;
					isInterested = haveProfile && buyer.is_interested == '1';
					if (buyer.is_interested == '1') userInterest = 'Interested';
					else if (buyer.is_interested == '2') userInterest = 'Not Interested';
					buyeritem = {
						orderNo: buyer_set.id,
						buyerId: buyer.uniquerid, // Assuming `buyer_id` is a property of `buyer`
						steps: [
							{ isHighlighted: qrScanned, label: 'QR Scanned' },
							{ isHighlighted: isRegistered, label: 'Registered' },
							{ isHighlighted: haveProfile, label: 'Created Profile' },
							{ isHighlighted: isInterested, label: userInterest },
						],
					};
					customers.push(buyeritem);
				}
				// setOrderData(buyers.buyerHandlers);
			} catch (error) {
				console.error('Failed to fetch buyer set customers:', error);
			}
			setOrderData(customers);
		} else {
			setOrderData([]);
		}
	};

	const handleCheckAll = () => {
		if (!orderData.length) return;
		const newCheckedOrders = {};
		orderData.forEach((order) => {
			newCheckedOrders[order.orderNo] = !isCheckAllChecked;
		});
		setCheckedOrders(newCheckedOrders);
		setIsCheckAllChecked(!isCheckAllChecked);
	};

	const handleCheckboxChange = (orderNo: string) => {
		setCheckedOrders((prevState) => {
			const newCheckedOrders = { ...prevState };
			newCheckedOrders[orderNo] = !newCheckedOrders[orderNo];
			return newCheckedOrders;
		});
	};

	const handleInputChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const { name, value } = e.target;
		setFormData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleFormSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		setShowPDFModal(true);
	};

	const fetchData = async () => {
		try {
			const buyers = await fetchgroupOffers();
			setGroupOffers(buyers);
		} catch (error) {
			console.error('Failed to fetch buyer sets:', error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<div className="container-fluid">
			<div className="group-offers-container">
				<h4 className="adminTitle mb-0">View Order Tracker</h4>
				<div className=" mb-3 mt-4">
					{/* d-flex justify-content-start */}
					<div className="row">
						<Col md={5} lg={5} sm={12} xs={12}>
							<div className="input-group">
								<select
									className="form-select rounded-pill"
									value={selectedOffer ? selectedOffer.id : ''}
									onChange={handleOfferChange}
								>
									<option value="">Select Group Offer</option>
									{groupOffers
										.filter((groupOffer) => groupOffer.buyersetId !== null)
										.map((groupOffer) => (
											<option key={groupOffer.id} value={groupOffer.id}>
												{groupOffer.name} - {groupOffer.formatted_id}
											</option>
										))}
								</select>
							</div>

						</Col>

						{selectedOffer && (
							// buyer_set_id

							<Col md={5} lg={5} sm={12} xs={12}>
								<div className="form-group d-flex align-items-center">
									<label
										style={{ minWidth: '100px' }}
										className="d-inline"
										htmlFor="buyerSetID"

									>
										Buyer Set ID
									</label>
									<Form.Control
										readOnly
										type="text"
										id="buyerSetID"
										placeholder="Buyer Set ID"
										className="rounded-pill"
										value={selectedOffer.buyer_set_id ?? ''}
										style={{ backgroundColor: '#E8E8E8' }}
									/>
								</div>
							</Col>
						)}

						<Col
							md={2}
							lg={2}
							sm={12}
							xs={12}
							className="d-flex justify-content-start"
						>
							{/*{selectedOffer && (
								<button className="ms-auto create-group-offer-button2 ">
									<input
										type="checkbox"
										checked={isCheckAllChecked}
										onChange={handleCheckAll}
									/>{' '}
									Check All
								</button>
							)}*/}
						</Col>
					</div>
				</div>
				<div className="group-offer-table-main overflow-auto">
					<table className="table table-bordered my-0">
						<tbody>
							{orderData.map((data, index) => (
								<OrderRow
									key={index}
									orderData={data}
									isChecked={checkedOrders[data.orderNo] || false}
									handleCheckboxChange={handleCheckboxChange}
									showCheckboxColumn={!!selectedOffer}
								/>
							))}
						</tbody>
					</table>
				</div>
				{/* <button
					className="ms-auto create-group-offer-button2 mt-3 mx-auto d-flex"
					onClick={() => setShowModal(true)}
				>
					Create Package
				</button> */}
			</div>

			<ModalCreatePackage
				show={showModal}
				onHide={() => setShowModal(false)}
				formData={formData}
				onInputChange={handleInputChange}
				onSubmit={handleFormSubmit}
			/>
			<ModalPDF
				show={showPDFModal}
				onHide={() => setShowPDFModal(false)}
				formData={formData}
				onInputChange={handleInputChange}
				onGeneratePackage={() => setSuccessModal(true)}
			/>
			<ModalSuccess
				show={successModal}
				onHide={() => setSuccessModal(false)}
				image={successImage}
			/>
		</div>
	);
};

export default Poliyogo;
