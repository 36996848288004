import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import BackImg from '../../../Image/back-arrow.svg';
import { createSku } from '../../../utils/api/admin';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import './style.css';
import SuccessModal from '../../../components/common/SuccessModal.tsx';

const CreateSKUForm: React.FC = () => {
	const [items, setItems] = useState([
		{ id: 1, description: '', quantity: 0, price: 0 },
	]);
	const [recurringItems, setRecurringItems] = useState([
		{ id: 1, description: '', quantity: 0, price: 0 },
	]);
	const [skuName, setSkuName] = useState('');
	const [turnKeyPrice, setTurnKeyPrice] = useState('');
	const [skuDescription, setSkuDescription] = useState('');
	const [activeLink, setActiveLink] = useState('AdminSKUList');
	const navigate = useNavigate();
//modal//
const [isOpen, setIsOpen] = useState(false); 
const [isSuccess, setIsSuccess] = useState(true); 
const [message, setMessage] = useState('SKU created successfully')
const [toNavigate, setToNavigate] = useState<string | null>(null)
	// Yup Validation Schema
	const validationSchema = Yup.object({
		skuName: Yup.string()
			.required('SKU Name is required')
			.min(3, 'SKU Name must be at least 3 characters long'),
		skuDescription: Yup.string()
			.required('SKU Description is required')
			.min(10, 'SKU Description must be at least 10 characters long'),
		turnKeyPrice: Yup.number()
			.required('Turnkey Price is required')
			.positive('Turnkey Price must be greater than or equal to 0')
			.min(0, 'Turnkey Price must be greater than or equal to 0'),
		items: Yup.array()
			.of(
				Yup.object({
					description: Yup.string()
						.required('Item description is required')
						.min(3, 'Item description must be at least 3 characters long'),
					quantity: Yup.number()
						.required('Item quantity is required')
						.positive('Item quantity must be greater than or equal to 1')
						.min(1, 'Item quantity must be greater than or equal to 1'),
					price: Yup.number()
						.required('Item price is required')
						.positive('Item price must be greater than or equal to 0')
						.min(0, 'Item price must be greater than or equal to 0'),
				})
			)
			.required('Items are required')
			.min(1, 'At least one item is required'),
		recurringItems: Yup.array()
			.of(
				Yup.object({
					description: Yup.string()
						.required('Charge description is required')
						.min(3, 'Charge description must be at least 3 characters long'),
					quantity: Yup.number()
						.required('Charge quantity is required')
						.positive('Charge quantity must be greater than or equal to 1')
						.min(1, 'Charge quantity must be greater than or equal to 1'),
					price: Yup.number()
						.required('Price is required')
						.positive('Price must be greater than or equal to 0')
						.min(0, 'Price must be greater than or equal to 0'),
				})
			)
			.required('Monthly recurring charges are required'),
	});

	const handleAddItem = () => {
		const newItem = { id: Date.now(), description: '', quantity: 0, price: 0 };
		setItems((prevItems) => [...prevItems, newItem]);
	};

	const handleDeleteItem = (id: number) => {
		setItems((prevItems) => prevItems.filter((item) => item.id !== id));
	};

	const handleLinkClick = (linkName: string) => {
		setActiveLink(linkName);
	};

	const handleInputChange = (
		id: number,
		field: string,
		value: string | number
	) => {
		setItems((prevItems) =>
			prevItems.map((item) =>
				item.id === id ? { ...item, [field]: value } : item
			)
		);
	};

	const handleRecurringChange = (
		id: number,
		field: string,
		value: string | number
	) => {
		setRecurringItems((prevItems) =>
			prevItems.map((item) =>
				item.id === id
					? {
							...item,
							[field]:
								field === 'quantity' || field === 'price'
									? parseInt(value.toString()) || 0
									: value,
					  }
					: item
			)
		);
	};

	const handleSubmit = async (values: any) => {
		const itemData = values.items.map(({ description, quantity, price }) => ({
			description,
			size: quantity,
			price,
			total_price: quantity * price,
		}));

		const recurringData = values.recurringItems.map(
			({ description, quantity, price }) => ({
				description,
				size: quantity,
				price,
				total_price: quantity * price,
			})
		);

		const skuData = {
			name: values.skuName,
			description: values.skuDescription,
			total: recurringData.reduce(
				(acc, item) => acc + item.size * item.price,
				0
			),
			item: itemData,
			monthly_recurring_charge: recurringData[0] || {},
			total_turn_key_price: values.turnKeyPrice,
		};

		try {
			await createSku(
				skuData.name,
				skuData.description,
				skuData.total,
				skuData.item,
				skuData.monthly_recurring_charge,
				skuData.total_turn_key_price
			);
			setToNavigate('/admin/sku-list');
      setIsOpen(true)
      
		} catch (error) {
			console.error('Failed to create SKU:', error);
		}
	};

	return (
		<div className="container">
			<div className="group-offers-container">
				<Link
					to="/admin/sku-list"
					onClick={() => handleLinkClick('AdminSKUList')}
					className={`text-decoration-none text-black mb-0 ${
						activeLink === 'AdminSKUList' ? 'active' : ''
					}`}
				>
					<h4 className="mb-0 adminTitle">
						<img
							src={BackImg}
							alt="Back"
							style={{ width: '20px', height: '20px', marginRight: '8px' }}
						/>
						Create New SKU
					</h4>
				</Link>

				<Formik
					initialValues={{
						skuName: skuName,
						skuDescription: skuDescription,
						turnKeyPrice: turnKeyPrice,
						items: items,
						recurringItems: recurringItems,
					}}
					validationSchema={validationSchema}
					onSubmit={handleSubmit}
				>
					{({ values, handleChange, handleBlur, setFieldValue }) => (
						<Form>
							<div className="card mt-4 p-3">
								<div className="mb-3">
									<label htmlFor="skuName" className="form-label">
										SKU Name
									</label>
									<Field
										type="text"
										className="form-control"
										id="skuName"
										name="skuName"
										value={values.skuName}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder="SKU Name"
									/>
									<ErrorMessage
										name="skuName"
										component="div"
										className="text-danger"
									/>
								</div>

								<div className="mb-3">
									<label htmlFor="skuDescription" className="form-label">
										SKU Description
									</label>
									<Field
										as="textarea"
										className="form-control"
										id="skuDescription"
										name="skuDescription"
										rows={3}
										value={values.skuDescription}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder="SKU Description"
									/>
									<ErrorMessage
										name="skuDescription"
										component="div"
										className="text-danger"
									/>
								</div>

								<div className="table-container">
									<h5>Items</h5>
									<table className="table table-bordered">
										<thead>
											<tr>
												<th>S.N.</th>
												<th>Description</th>
												<th>Quantity</th>
												<th>Actions</th>
											</tr>
										</thead>
										<tbody>
											{values.items.map((item, index) => (
												<tr key={item.id}>
													<td>{index + 1}</td>
													<td>
														<Field
															type="text"
															name={`items[${index}].description`}
															value={item.description}
															onChange={handleChange}
															className="form-control"
														/>
														<ErrorMessage
															name={`items[${index}].description`}
															component="div"
															className="text-danger"
														/>
													</td>
													<td>
														<Field
															type="number"
															name={`items[${index}].quantity`}
															value={item.quantity}
															onChange={handleChange}
															className="form-control"
														/>
														<ErrorMessage
															name={`items[${index}].quantity`}
															component="div"
															className="text-danger"
														/>
													</td>
													<td>
														<button
															type="button"
															className="btn btn-danger"
															onClick={() => {
																const newItems = values.items.filter(
																	(_, i) => i !== index
																);
																setFieldValue('items', newItems);
															}}
														>
															Delete
														</button>
													</td>
												</tr>
											))}
										</tbody>
									</table>
									<button
										type="button"
										className="btn btn-primary"
										onClick={() => {
											const newItem = {
												id: Date.now(),
												description: '',
												quantity: 0,
												price: 0,
											};
											setFieldValue('items', [...values.items, newItem]);
										}}
									>
										Add Item
									</button>
								</div>

								<div className="d-flex justify-content-between mt-4">
									<h5>Total Turn Key Price</h5>
									<div className="col-md-4">
										<Field
											type="number"
											name="turnKeyPrice"
											className="form-control"
											value={values.turnKeyPrice}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										<ErrorMessage
											name="turnKeyPrice"
											component="div"
											className="text-danger"
										/>
									</div>
								</div>
								<h5 className="mt-4">Monthly Recurring Charge</h5>
								<table className="table table-bordered">
									<thead>
										<tr>
											<th>S.N.</th>
											<th>Description</th>
											<th>Quantity</th>
											<th>Price</th>
											<th>Total Price</th>
										</tr>
									</thead>
									<tbody>
										{values.recurringItems.map((item, index) => (
											<tr key={item.id}>
												<td>{index + 1}</td>
												<td>
													<Field
														type="text"
														name={`recurringItems[${index}].description`}
														value={item.description}
														onChange={handleChange}
														className="form-control"
													/>
													<ErrorMessage
														name={`recurringItems[${index}].description`}
														component="div"
														className="text-danger"
													/>
												</td>
												<td>
													<Field
														type="number"
														name={`recurringItems[${index}].quantity`}
														value={item.quantity}
														onChange={handleChange}
														className="form-control"
													/>
													<ErrorMessage
														name={`recurringItems[${index}].quantity`}
														component="div"
														className="text-danger"
													/>
												</td>
												<td>
													<Field
														type="number"
														name={`recurringItems[${index}].price`}
														value={item.price}
														onChange={handleChange}
														className="form-control"
													/>
													<ErrorMessage
														name={`recurringItems[${index}].price`}
														component="div"
														className="text-danger"
													/>
												</td>
												<td>${item.quantity * item.price || 0}</td>
											</tr>
										))}
									</tbody>
								</table>

								<button
									type="submit"
									className="create-group-offer-button2 w-fit"
								>
									Generate
								</button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
      <SuccessModal
      navigateTo={toNavigate}
     isOpen={isOpen}
     onClose={() => setIsOpen(false)}
     isSuccess={isSuccess}
     message={message}
/>
		</div>
	);
};

export default CreateSKUForm;
