import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../../inc/admin/Footer.tsx';
import Header from '../../inc/admin/Header.tsx';
import Sidebar from '../../inc/admin/Sidebar.tsx';
import './admin.css';


const AdminLayout: React.FC = () => {
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 992);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 992);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={`admin-layout h-100 ${isSmallScreen ? 'admin-layout-sm' : ''}`}>
      <div className="d-flex h-100">
        <Sidebar />
        <div className="admin-main h-100 d-flex flex-column">
          <Header />
          <main className="admin-content pb-5 mb-5 h-100 d-flex flex-column mt-5">
            <Outlet /> {/* Nested routes will render here */}
          </main>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
