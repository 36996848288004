import React from 'react';
// import Video from '../../Image/image 12.png';

const Title: React.FC = () => {
    return (
        <div>
            <h2 className="line-title text-center barlow-condensed">WE ARE ACTIVELY CULTIVATING A SUSTAINABLE FUTURE!</h2> {/* biggest size, font name "Barlow Condensed" in BOLD] */}
            <h2 className="line-title text-center barlow-condensed">Energy generation is no longer about doing less harm. It's about doing more good. </h2> {/* biggest size, font name "Barlow Condensed" in BOLD] */}
            <p className='big-paragraph'>Join a community that believes in reducing the impact of climate change and enjoy the benefits of savings on your electric bills.</p> {/* bigger size] */}
            <p>Welcome to ComSol - Community Solar by Synergy. You are invited to join us and avail an attractive solution for your energy needs and savings for your family. ComSol enables communities to go green by harnessing renewable energy. Electricity generated through our solar solution significantly helps reduce your electric bills for your family. SAVINGS! on your electric bills, while also contributing to the fight against climate change. Start saving, and secure a sustainable, cost-effective future for your family.</p>
            <p>Harness the power of the sun. We are offering an attractive turnkey solar solution to setup on your rooftop. Thank you for scanning your personalized QR Code. To reserve your spot sign up. As soon as we hear back from you and others in the community, look out for communication from us.</p>
            {/* <img src={Video} alt="Descriptive Slide" className="w-75  mt-5 mx-auto d-flex" /> */}
        </div>
    );
};

export default Title;
