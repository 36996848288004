import React, { useState, useEffect } from 'react';
import threeDots from '../../../Image/threeDots.svg';
import SendGroupModal from '../GroupOffer/SendModal.tsx';
import { fetchSku } from '../../../utils/api/admin.js';

const GroupOffersTable: React.FC<any> = ({
  setGroupOfferId,
  groupOffers,
  handleModalToggle,
  handleEditGroupOffer,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedGroupOffer, setSelectedGroupOffer] = useState<any>(null);
  const [skuOptions, setSkuOptions] = useState<any[]>([]);

  const handleSendClick = (groupOffer: any) => {
    setSelectedGroupOffer(groupOffer);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedGroupOffer(null);
  };

  const fetchData = async () => {
    try {
      const skus = await fetchSku();
      setSkuOptions(skus);
    } catch (error) {
      console.error('Failed to fetch SKU options:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="group-offers-container">
        <div className="heading-container align-items-center d-flex justify-content-between">
          <h4 className="mb-0 adminTitle">GROUP OFFERS</h4>
          <button
            className="create-group-offer-button2"
            onClick={() => handleModalToggle('create')}
          >
            Create Group Offer
          </button>
        </div>

        <div className="group-offer-table-main">
          <table className="group-offers-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Group Name</th>
                <th>SKU Name</th>
                <th>List Price</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {groupOffers.length > 0 ? (
                groupOffers.map((offer, index) => (
                  <tr key={index}>
                    <td>{offer.formatted_id}</td>
                    <td>{offer.name}</td>
                    <td>{offer.sku_name}</td>
                    <td>{offer.list_price}</td>
                    <td>
                      {new Date(offer.start_date).toLocaleDateString('es-CO', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                      })}
                    </td>
                    <td>
                      {new Date(offer.end_date).toLocaleDateString('es-CO', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                      })}
                    </td>
                    <td>
                      <div className="dropdown">
                        <button
                          className="threeDots inTable d-flex border-0 justify-content-center align-items-center"
                          type="button"
                          id={`dropdownMenuButton-${offer.id}`}
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img src={threeDots} alt="threeDots" />
                        </button>
                        <ul
                          className="dropdown-menu dropdown-menu-dark end-0 start-inherit py-0"
                          aria-labelledby={`dropdownMenuButton-${offer.id}`}
                        >
                          <li>
                            <button
                              disabled={offer.buyersetId ? true : false}
                              className={offer.buyersetId ? 'dropdown-item text-muted' : 'dropdown-item'}
                              onClick={() => handleSendClick(offer)}
                            >
                              Send Group
                            </button>
                          </li>
                          <li>
                            <button
                              disabled={offer.buyersetId ? true : false}
                              className={offer.buyersetId ? 'dropdown-item text-muted' : 'dropdown-item'}
                              onClick={() => handleEditGroupOffer(offer)}
                            >
                              Edit Group
                            </button>
                          </li>
                          <li>
                            <button className="dropdown-item" onClick={() => alert('To be integrated soon')}>
                              Create Package
                            </button>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" style={{ color: 'red', textAlign: 'center' }}>
                    No Data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {isModalOpen && selectedGroupOffer && (
        <SendGroupModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          groupName={selectedGroupOffer.name}
          buyerSet={selectedGroupOffer.sku_id}
          SkuOption={skuOptions}
          buyerOptionId={selectedGroupOffer.id}
        />
      )}
    </>
  );
};

export default GroupOffersTable;
