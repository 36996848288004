import React from 'react';

interface FatArrowProps {
  content: string;
  isHighlighted: boolean;
}

const FatArrow: React.FC<FatArrowProps> = ({ content, isHighlighted }) => {
  return (
    <div className={`fat-arrow ${isHighlighted ? 'highlight' : ''}`}>
      <div className="inner">{content}</div>
    </div>
  );
};

export default FatArrow;
