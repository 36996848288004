// src/components/admin/SKUList/SKUListTable.tsx
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SKUList from '../../../components/admin/SKUList/SKUListTable.tsx'; // Import the table component
import SKUModal from '../../../components/admin/SKUList/SKUModal.tsx'; // Import the modal component
import { deleteSku, fetchSku } from '../../../utils/api/admin.js';

interface Sku {
    name: string;
    id: string;
    created_at: string;
}

const SKUListTable = () => {
    const [activeLink, setActiveLink] = useState('');
    const [selectedSku, setSelectedSku] = useState<Sku | null>(null);
    const [editMode, setEditMode] = useState(false);
    const [skuData, setSkuData] = useState([]);

    const fetchData = async () => {
        try {
          const buyers = await fetchSku();
          setSkuData(buyers);
        } catch (error) {
          console.error('Failed to fetch SKU list:', error);
        }
      };
    
      useEffect(() => {
        fetchData();
      }, []);

    const handleLinkClick = (linkName) => {
        setActiveLink(linkName);
      };

    const handleView = (sku: Sku) => {
        setSelectedSku(sku);
        setEditMode(false); // Open modal in view mode
    };

    const handleEdit = (sku: Sku) => {
        setSelectedSku(sku);
        setEditMode(true); // Open modal in edit mode
    };

    const handleDelete = async (id: string) => {
        try {
            await deleteSku(id);
            setSkuData(skuData.filter((item) => item.id !== id));
        } catch (error) {
            console.error('Failed to delete SKU:', error);
            alert('Error deleting SKU. Please try again.'); // Optional: Notify the user
        }
    };

    return (
        <div className="container">
            <div className="group-offers-container">
                <div className="heading-container align-items-center d-flex justify-content-between">
                    <h4 className="mb-0 adminTitle">SKU List</h4>
                    <Link
                        to="/admin/sku-new"
                        onClick={() => handleLinkClick('createNewSku')}
                        className={`text-decoration-none create-group-offer-button2 ${activeLink === 'createNewSku' ? 'active' : ''}`}
                    >
                        Create New SKU
                    </Link>
                </div>

                {/* SKU Table Component */}
                <SKUList
                    skuData={skuData}
                    onView={handleView}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                />

                {/* SKU Modal Component */}
                {selectedSku && (
                    <SKUModal
                        sku={selectedSku}
                        editMode={editMode}
                        onClose={() => setSelectedSku(null)}
                        onSaveEdit={(updatedSku) => {
                            setSkuData(prevData =>
                                prevData.map(item => item.id === updatedSku.id ? { ...updatedSku } : item)
                            );
                            setSelectedSku(null);
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default SKUListTable;
