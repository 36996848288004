import React from 'react';
import FatArrow from './FatArrow.tsx';

interface Step {
  label: string;
  isHighlighted: boolean;
}

interface OrderData {
  orderNo: string;
  buyerId: string;
  steps: Step[];
}

interface OrderRowProps {
  orderData: OrderData;
  isChecked: boolean;
  handleCheckboxChange: (orderNo: string) => void;
  showCheckboxColumn: boolean;
}

const OrderRow: React.FC<OrderRowProps> = ({ orderData, isChecked, handleCheckboxChange, showCheckboxColumn }) => {
  return (
    <tr>
      <td className="order-info">
        <div className="order-number">Order No. {orderData.orderNo}</div>
        <div className="buyer-id">Buyer ID -: {orderData.buyerId}</div>
      </td>
      <td className="workflow-steps">
        {orderData.steps.map((step, index) => (
          <FatArrow key={index} content={step.label} isHighlighted={step.isHighlighted} />
        ))}
      </td>
      {showCheckboxColumn && (
        <td>
          <input
            type="checkbox"
            checked={isChecked}
            onChange={() => handleCheckboxChange(orderData.orderNo)}
          />
        </td>
      )}
    </tr>
  );
};

export default OrderRow;
