import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import logoWithText from '../../Image/logo-with-text.svg';

interface NavbarProps {
    setCurrentPage: (page: string) => void;
}

const Navbar: React.FC<NavbarProps> = ({ setCurrentPage }) => {
    const { isAuthenticated, logout } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const [authStatus, setAuthStatus] = useState(isAuthenticated);

    const handleLogout = () => {
        logout();
        navigate('/login');
      };

    // Update auth status on component mount
    useEffect(() => {
        setAuthStatus(isAuthenticated);
    }, [isAuthenticated]);

    const isLoginPage = location.pathname === '/login';
    const isHomePage = location.pathname === '/';

    return (
        <nav className="navbar navbar-expand-lg top-navbar fixed-top">
            <div className="container-fluid">
                <span className="navbar-brand">
                    <div className="first top-navbar">
                        <div className="container d-flex justify-content-between align-items-center">
                            <Link to="/" onClick={() => setCurrentPage('/')}>
                                <img src={logoWithText} alt="Logo" className="logo-with-text" />
                            </Link>
                        </div>
                    </div>
                </span>

                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                    <div className="navigation d-flex gap-4">
                        <Link
                            to="/about"
                            onClick={() => setCurrentPage('/about')}
                            className="solutions-btn border-0 bg-transparent text-white text-decoration-none"
                        >
                            About
                        </Link>
                        <Link
                            to="/solutions"
                            onClick={() => setCurrentPage('/solutions')}
                            className="solutions-btn border-0 bg-transparent text-white text-decoration-none"
                        >
                            Solutions
                        </Link>

                        {!authStatus && (
                            <>
                                <Link to="/login" className="text-decoration-none">
                                    <button
                                        className={`signup-btn ${isLoginPage ? 'login-btn-active' : ''}`}
                                    >
                                        Login
                                    </button>
                                </Link>
                                {/* <Link to="/" className="text-decoration-none">
                                    <button
                                        className={`signup-btn ${isHomePage ? 'login-btn-active' : ''}`}
                                    >
                                        Sign Up
                                    </button>
                                </Link> */}
                            </>
                        )}
                        {authStatus && (
                            <Link to="/" className="text-decoration-none">
                                <button className="signup-btn" onClick={handleLogout}>Logout</button>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
