import React from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import ImageUploadComponent from './ImageUpload.tsx';

interface GroupOffer {
  buyer_set_name: string;
  buyer_set_id: string;
  created_at: string;
  handler_id_check?: number;
  QR?: string;
  updated_at?: string | null;
  selectedImage: File | null;
}

interface Props {
  showModal: boolean;
  handleClose: () => void;
  formData: GroupOffer;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleImageSelect: (imageFile: File) => void;
  handleFormSubmit: (e: React.FormEvent, isEdit: boolean) => Promise<void>;
  isEdit: boolean;
}

const BuyerSetModal: React.FC<Props> = ({
  showModal,
  handleClose,
  formData,
  handleInputChange,
  handleImageSelect,
  handleFormSubmit,
  isEdit,
}) => {
  return (
    <Modal show={showModal} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton className="border-0">
        <Modal.Title className="fw-bold">{isEdit ? 'Edit' : 'Create'} Buyer Set</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0 mt-0">
        <Form onSubmit={(e) => handleFormSubmit(e, isEdit)}>
          <Row className="g-2 align-items-start mb-3">
            <Col md={6}>
              <Form.Group controlId="buyer_set_name">
                <Form.Label>Buyer Set Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Buyer Set Name"
                  name="buyer_set_name"
                  value={formData.buyer_set_name}
                  onChange={handleInputChange}
                  required
                  className="rounded-pill"
                />
              </Form.Group>
              <Form.Group controlId="buyer_set_id" className="mt-3">
                <Form.Label>Buyer Set ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Buyer Set ID"
                  name="buyer_set_id"
                  value={formData.buyer_set_id}
                  onChange={handleInputChange}
                  required
                  className="rounded-pill"
                />
              </Form.Group>
            </Col>
            {isEdit ?
              <Col md={6}>
                <Form.Group controlId="created_at">
                  <Form.Label>Buyer Set Created Date</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    placeholder="Buyer Set Created Date"
                    name="created_at"
                    value={
                      formData.created_at
                        ? new Date(new Date(formData.created_at).getTime() - new Date().getTimezoneOffset() * 60000)
                          .toISOString()
                          .slice(0, 16)
                        : ''
                    }
                    onChange={handleInputChange}
                    required
                    className="rounded-pill"
                  />
                </Form.Group>
              </Col>
              : ''}
            {!isEdit ? <Col md={6}>
              <ImageUploadComponent onImageSelect={handleImageSelect} />
            </Col> : ''}

            <Col md={4} className="mx-auto mt-3">
              <button type="submit" className="w-100 create-group-offer-button2">
                {isEdit ? 'Update' : 'Create'}
              </button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default BuyerSetModal;
