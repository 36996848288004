import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Modal, Row, Toast, ToastContainer } from 'react-bootstrap';
import qrCode from '../../../Image/qrCode.svg'; // Update path to your QR code image
import { fetchSku } from '../../../utils/api/admin';
import SuccessModal from '../../common/SuccessModal.tsx'; // Ensure the path is correct

interface GroupOfferFormData {
  name: string;
  start_date: string;
  end_date: string;
  sku_id: string;
  discount: number;
  discount_price: number;
  list_price: number;
}

interface CreateEditModalProps {
  isOpen: boolean;
  modalType: string | null;
  onClose: () => void;
  formData: GroupOfferFormData;
  setFormData: React.Dispatch<React.SetStateAction<GroupOfferFormData>>;
  onSubmit: (e: React.FormEvent) => Promise<string>; // Assuming onSubmit returns a generated code
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => void;
  errors: Record<string, string | null>;
  turnPrice: number | null;
  setTurnPrice: (price: number|null) => void;
  discountPrice: number | null;
}

interface SkuOption {
  id: string;
  name: string;
  turn_key_price: number;
}

const CreateEditModal: React.FC<CreateEditModalProps> = ({
  isOpen,
  modalType,
  onClose,
  formData,
  setFormData,
  onSubmit,
  handleInputChange,
  errors,
  turnPrice,
  setTurnPrice,
  discountPrice,
}) => {
  const [skuOptions, setSkuOptions] = useState<SkuOption[]>([]);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [generatedCode, setGeneratedCode] = useState<string>(''); // To store generated code
  const [modalMessage, setModalMessage] = useState<string>('');

  // Fetch SKU options
  const fetchData = async () => {
    try {
      const skus = await fetchSku();
      setSkuOptions(skus);
    } catch (error) {
      console.error('Failed to fetch SKU options:', error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setModalMessage(modalType == 'edit' ? 'Group Offer Updated Successfully' : 'Group Offer Created Successfully');
      fetchData();
    }
  }, [isOpen]);

  // Validate form data
  const validateForm = () => {
    return (
      formData?.name?.trim() &&
      formData?.start_date &&
      formData?.end_date &&
      formData?.sku_id &&
      formData?.discount &&
      turnPrice !== null
    );
  };

  // Form submission handler
  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault(); // Prevent page reload

    if (validateForm()) {
      try {
        // Assuming onSubmit returns a generated code
        const code = await onSubmit(e);
        setGeneratedCode(code); // Store the generated code
        setSuccessModalOpen(true); // Show Success Modal
        onClose(); // Close the Create/Edit Modal
        resetFormData(); // Reset form data
      } catch (error) {
        console.error('Submission failed:', error);
        setShowToast(true); // Show toast for submission errors
      }
    } else {
      setShowToast(true); // Show toast for validation errors
    }
  };

  // Handle SKU select input change
  const handleSelectInputChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedIndex = e.target.options[e.target.selectedIndex].getAttribute('data-index');
    if (selectedIndex !== null) {
      const sku = skuOptions[parseInt(selectedIndex)];
      setTurnPrice(sku.turn_key_price);
      setFormData((prev) => ({ ...prev, sku_id: sku.id }));
    }
  };

  // Reset form data on modal close
  const resetFormData = () => {
    setFormData({
      name: '',
      start_date: '',
      end_date: '',
      sku_id: '',
      discount: 0,
      discount_price: 0,
      list_price: 0,
    });
    setTurnPrice(null); // Reset turn price as well
  };

  const onCloseHandler = () => {
    resetFormData(); // Reset form data when closing the modal
    onClose(); // Close the modal
  };

  return (
    <>
      <Modal show={isOpen} onHide={onCloseHandler} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {modalType === 'edit' ? 'EDIT GROUP OFFER' : 'CREATE GROUP OFFER'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Row className="g-2 align-items-center mb-3">
              <Col md={8}>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="groupName">
                      <Form.Label>Group Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Group Name"
                        name="name"
                        maxLength={64}
                        value={formData?.name}
                        onChange={handleInputChange}
                        className={`rounded-pill ${errors?.name ? 'is-invalid' : ''}`}
                      />
                      {errors?.name && <div className="invalid-feedback">{errors?.name}</div>}
                    </Form.Group>
                  </Col>
                  <Col md={6}></Col>

                  <Col md={6}>
                    <Form.Group controlId="start_date">
                      <Form.Label>Start Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="start_date"
                        value={formData?.start_date}
                        onChange={handleInputChange}
                        min={new Date().toISOString().split('T')[0]}
                        className={`rounded-pill ${errors?.start_date ? 'is-invalid' : ''}`}
                      />
                      {errors?.start_date && (
                        <div className="invalid-feedback">{errors?.start_date}</div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group controlId="end_date">
                      <Form.Label>End Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="end_date"
                        value={formData?.end_date}
                        onChange={handleInputChange}
                        readOnly={!formData?.start_date}
                        style={{
                          backgroundColor: !formData?.start_date ? '#E8E8E8' : '#fff',
                        }}
                        min={
                          formData?.start_date || new Date().toISOString().split('T')[0]
                        } // Disable dates before start_date
                        className={`rounded-pill ${errors?.end_date ? 'is-invalid' : ''}`}
                      />
                      {errors?.end_date && (
                        <div className="invalid-feedback">{errors?.end_date}</div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group controlId="sku_id">
                      <Form.Label>Select SKU-ID</Form.Label>
                      <Form.Select
                        name="sku_id"
                        value={formData?.sku_id}
                        onChange={handleSelectInputChange}
                        className={`rounded-pill ${errors?.sku_id ? 'is-invalid' : ''}`}
                      >
                        <option value="">Select SKU-ID</option>
                        {skuOptions.map((option, index) => (
                          <option key={option?.id} value={option?.id} data-index={index}>
                            {option?.name}
                          </option>
                        ))}
                      </Form.Select>
                      {errors?.sku_id && (
                        <div className="invalid-feedback">{errors?.sku_id}</div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group controlId="list_price">
                      <Form.Label>List Price</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="List Price"
                        name="list_price"
                        value={turnPrice || ''}
                        readOnly
                        style={{ backgroundColor: '#E8E8E8' }}
                        className={`rounded-pill ${errors?.list_price ? 'is-invalid' : ''}`}
                      />
                      {errors?.list_price && (
                        <div className="invalid-feedback">{errors?.list_price}</div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group controlId="discount">
                      <Form.Label>Discount (%)</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Discount"
                        name="discount"
                        value={formData?.discount}
                        onChange={(e) => {
                          let value = parseInt(e.target.value, 10);

                          // Enforce min and max bounds
                          if (value < 1) value = 1;
                          if (value > 99) value = 99;

                          handleInputChange({
                            target: { name: 'discount', value: value.toString() },
                          });
                        }}
                        readOnly={!turnPrice}
                        style={{
                          backgroundColor: !turnPrice ? '#E8E8E8' : '#fff',
                        }}
                        className={`rounded-pill ${errors?.discount ? 'is-invalid' : ''}`}
                      />
                      {errors?.discount && (
                        <div className="invalid-feedback">{errors?.discount}</div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group controlId="discount_price">
                      <Form.Label>Price After Discount</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Discount Price"
                        name="discount_price"
                        value={discountPrice || ''}
                        style={{ backgroundColor: '#E8E8E8' }}
                        readOnly
                        className={`rounded-pill ${errors?.discount_price ? 'is-invalid' : ''}`}
                      />
                      {errors?.discount_price && (
                        <div className="invalid-feedback">
                          {errors?.discount_price}
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col md={6} className="mx-auto mt-4">
                    <Button
                      type="submit"
                      className="w-100 create-group-offer-button2 rounded-pill"
                    >
                      {modalType === 'edit' ? 'Update' : 'Create'}
                    </Button>
                  </Col>
                </Row>
              </Col>

              <Col md={4} className="d-flex flex-column align-items-center">
                <img className="m-auto qrImg" src={qrCode} alt="QR Code" />
                <Button variant="success" className="w-100 mt-3 rounded-pill">
                  Generate QR Code
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Success Modal */}
      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={() => setSuccessModalOpen(false)}
        isSuccess={true}
        message={modalMessage}
        navigateTo={null}
      />


      {/* Toast Notification */}
      <ToastContainer position="top-center" className="p-3" style={{ zIndex: 11111 }}>
        <Toast
          bg="danger"
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={5000}
          autohide
        >
          <Toast.Body>Form validation failed! Please fill in all required fields.</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default CreateEditModal;
