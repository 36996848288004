import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';

const OrderSuccess: React.FC = () => {
    return (
        <div className="bodyScroll">
            <div className="container text-white pt-3">
                <div className="row g-4">
                    {/* Left Section - Order Success */}
                    <div className="col-md-8 col-12">
                        <h2 className="mb-4">ORDER SUCCESS</h2>
                        <div className="bg-white text-dark p-4 rounded">
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                            <div className="mt-4">
                                <h4>Pricing</h4>
                                <ul className="list-unstyled">
                                    <li>Line Items - 6</li>
                                    <li>Quantity - 20</li>
                                    <li>Size - 18B</li>
                                    <li>Maintenance & Service Charges - ₹ 200/-</li>
                                </ul>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-4 border-top pt-3">
                                <span className="fw-bold">Total Cost</span>
                                <span className="fw-bold fs-5">₹ 3999.00/Mo</span>
                            </div>
                        </div>
                        <button className="create-group-offer-button2 w-50 mt-3 mx-auto d-flex justify-content-center mb-3">
                            Install Now
                        </button>
                    </div>

                    {/* Right Section - Order Status */}
                    <div className="col-md-4">
                        <h2 className="mb-4">ORDER STATUS</h2>
                        <div className="bg-white text-dark p-4 rounded">
                            <div className="d-flex justify-content-between text-center mb-4">
                                <div>
                                    <h3 className="mb-1">00</h3>
                                    <p>Days</p>
                                </div>
                                <div>
                                    <h3 className="mb-1">00</h3>
                                    <p>Hours</p>
                                </div>
                                <div>
                                    <h3 className="mb-1">00</h3>
                                    <p>Minutes</p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <span>Status</span>
                                <span className="badge bg-success">Complete</span>
                            </div>
                            <div>
                                <p>Before 10 days: <strong>5 buyers joined</strong></p>
                                <p>This week: <strong>9 buyers joined</strong></p>
                                <p>Today: <strong>1 buyer joined</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderSuccess;
