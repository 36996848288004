import React from 'react';
import { Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ImageUploadComponent from './ImageUpload.tsx';

interface GroupOffer {
  id: string;
  buyer_set_name: string;
  buyer_set_id: string;
  created_at: string;
  handler_id_check?: number;
  QR?: string;
  updated_at?: string | null;
  selectedImage: File | null;
}

interface Props {
  showModal: boolean;
  handleClose: () => void;
  formData: GroupOffer;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleImageSelect: (imageFile: File) => void;
  handleFormSubmit: (e: React.FormEvent, isEdit: boolean) => Promise<void>;
  isEdit: boolean;
  isLoading: boolean; // Added isLoading prop
}

const BuyerSetModal: React.FC<Props> = ({
  showModal,
  handleClose,
  formData,
  handleInputChange,
  handleImageSelect,
  handleFormSubmit,
  isEdit,
  isLoading, // Use isLoading prop here
}) => {
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    await handleFormSubmit(e, isEdit);
  };

  const handleViewMore = () => {
    // Navigate to the BuyerDetails page
    // console.log(formData);
    navigate(`/admin/buyer-sets/${formData.id}`);
  };

  return (
    <Modal show={showModal} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton className="border-0">
        <Modal.Title className="fw-bold">{isEdit ? 'Edit' : 'Create'} Buyer Set</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0 mt-0">
        <Form onSubmit={handleSubmit}>
          <Row className="g-2 align-items-start mb-3">
            <Col md={6}>
              <Form.Group controlId="buyer_set_name">
                <Form.Label>Buyer Set Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Buyer Set Name"
                  name="buyer_set_name"
                  value={formData.buyer_set_name}
                  onChange={handleInputChange}
                  required
                  className="rounded-pill"
                  disabled={isLoading} // Disable input when loading
                />
              </Form.Group>

              {isEdit && (
                <Form.Group controlId="buyer_set_id" className="mt-3">
                  <Form.Label>Buyer Set ID</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Buyer Set ID"
                    name="buyer_set_id"
                    value={formData.buyer_set_id}
                    onChange={handleInputChange}
                    readOnly
                    required
                    className="border-0 rounded-pill"
                    disabled={isLoading}
                    style={{ backgroundColor: '#E8E8E8' }}
                  />
                </Form.Group>
              )}
            </Col>

            {isEdit && (
              <Col md={6}>
                <Form.Group controlId="created_at">
                  <Form.Label>Buyer Set Created Date</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    name="created_at" 
                    value={
                      formData.created_at
                        ? new Date(new Date(formData.created_at).getTime() - new Date().getTimezoneOffset() * 60000)
                          .toISOString()
                          .slice(0, 16)
                        : ''
                    }
                    onChange={handleInputChange}
                    className="border-0 rounded-pill"
                    readOnly
                    required
                    disabled={isLoading}
                    style={{ backgroundColor: '#E8E8E8' }}
                  />
                </Form.Group>
              </Col>
            )}

            {!isEdit && (
              <Col md={6}>
                <a href="/SampleBuyerDataset.csv" download className="text-decoration-none theme-color mb-2">
                  Sample Of CSV File
                </a>
                <ImageUploadComponent onImageSelect={handleImageSelect} />
              </Col>
            )}

            <Row className="mx-auto mt-3">
              <Col md={6}>
                {!isLoading ? (
                  <button type="submit" className="w-100 create-group-offer-button2">
                    {isEdit ? 'Update' : 'Create'}
                  </button>
                ) : (
                  <div className="text-center">
                    <Spinner animation="border" role="status" className="text-primary" />
                  </div>
                )}
              </Col>

              {isEdit && (
                <Col md={6}>

                  <button
                    type="button"
                    className="w-100 create-group-offer-button2"
                    onClick={handleViewMore}
                    disabled={isLoading}
                  >
                    View More
                  </button>
                </Col>
              )}
            </Row>

          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default BuyerSetModal;
