import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import { AuthProvider, useAuth } from "./AuthContext.js";
import AuthRoute from "./components/AuthRoute.js";
import ProtectedRoute from "./components/ProtectedRoute.js";
import About from "./components/user/About.tsx";
import Solution from "./components/user/Solution.tsx";
import AdminLayout from "./layouts/admin/index.tsx";
import UserLayout from "./layouts/user/index.tsx";
import AdminBuyerDetails from "./pages/admin/BuyerSets/details.tsx";
import AdminBuyerSets from "./pages/admin/BuyerSets/index.tsx";
import AdminGroupOffers from "./pages/admin/GroupOffers/index.tsx";
import AdminLogin from "./pages/admin/Login.tsx";
import AdminOrderTracker from "./pages/admin/OrderTracker/index.tsx";
import AdminSKUCreate from "./pages/admin/SKUList/create.tsx";
import AdminSKUList from "./pages/admin/SKUList/index.tsx";
import AdminSKUView from "./pages/admin/SKUList/view.tsx";
import AdminSKUEdit from "./pages/admin/SKUList/edit.tsx";
import Login from "./pages/user/Login.tsx";
import Order from "./pages/user/Order.tsx";
import OrderSuccess from "./pages/user/OrderSuccess.tsx";
import PaymentForm from "./pages/user/PaymentForm.tsx";
import PaymentSuccess from "./pages/user/PaymentSuccess.tsx";
import Profile from "./pages/user/Profile.tsx";
import Signup from "./pages/user/Signup.tsx";
import TermsAndConditions from "./pages/user/TermsAndConditions.tsx";
import { qr_scanned } from "./utils/api/user.js";
import '@fontsource/barlow-condensed/700.css';

function App() {
  const { logout, isAuthenticated } = useAuth();
  const queryParams = new URLSearchParams(window.location.search);
  const handlarID = queryParams.get("handlarID");
  const handleHandlarID = async (handlarID) => {
    logout(); // Ensure this is synchronous or adjust if it's asynchronous
    try {
      await qr_scanned(handlarID);
    } catch (error) {
      console.error("Error in qr_scanned:", error);
    }
  };
  if (handlarID) handleHandlarID(handlarID);
  // useEffect(() => {
  //   // Extract handlarID from the URL
  //     // Call logout and then qr_scanned
  // }, [isAuthenticated]);
  return (
    <AuthProvider>
      <Router>
        <div>
          <Routes>
            {/* Authentication Routes */}
            <Route element={<AuthRoute />}>
              {/* Normal User */}
              <Route path="/" element={<UserLayout />}>
                <Route path="/" element={<Signup />} />
                <Route path="/login" element={<Login />} />
              </Route>
              {/* Admin */}
              <Route path="/admin/login" element={<AdminLogin />} />
            </Route>

            {/* User Unprotected Routes */}
            <Route path="/" element={<UserLayout />}>
              <Route path="/about" element={<About />} />
              <Route path="/solutions" element={<Solution />} />
              <Route path="/terms" element={<TermsAndConditions />} />
            </Route>

            {/* User Protected Routes */}
            <Route element={<ProtectedRoute />}>
              <Route path="/" element={<UserLayout />}>
                <Route path="/profile" element={<Profile />} />
                <Route path="/order" element={<Order />} />
                <Route path="/payment-form" element={<PaymentForm />} />
                <Route path="/payment-success" element={<PaymentSuccess />} />
                <Route path="/order-success" element={<OrderSuccess />} />
              </Route>
            </Route>

            {/* Admin Protected Routes */}
            <Route element={<ProtectedRoute />}>
              <Route path="/admin" element={<AdminLayout />}>
                <Route path="group-offers" element={<AdminGroupOffers />} />
                <Route path="buyer-sets" element={<AdminBuyerSets />} />
                <Route path="buyer-sets/:buyer_id" element={<AdminBuyerDetails />} />
                <Route path="sku-list" element={<AdminSKUList />} />
                <Route path="sku-new" element={<AdminSKUCreate />} />
                <Route path="sku-view/:id" element={<AdminSKUView />} />
                <Route path="sku-edit/:id" element={<AdminSKUEdit />} />
                <Route path="order-tracker" element={<AdminOrderTracker />} />
              </Route>
            </Route>
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
