import React, { FC } from 'react';
import sgr from '../../Image/image 12.png';
// import gr from '../../Image/image 13.png';
import gr from '../../Image/solution_page_image.webp';

const About: FC = () => {
	return (
		<div className="bodyScroll">
			<div className="d-flex justify-content-center line-title mt-2 mb-5">
				<h2>About Us</h2>
			</div>
			<div>
				<div className="container">
					<div className="card overflow-hidden p-4">
						{/* Section: Who We Are */}
						<div className="row align-items-center mb-5 border-bottom pb-4">
							<div className="col-md-7">
								<h2 className="mb-3">Who we are?</h2>
								<p className="text-muted">
									We are building a world driven by renewable power that
									uniquely positions us as the key enabler of the clean energy
									supply chain.
								</p>
								<p className="text-muted">
									We are revolutionizing the way grid stability, sustainability
									and scale can be met with the energy demands of today and the
									future. Due to expanding cities and climate change impacts,
									the demand is unpredictable. We enable these cities with
									in-metro distributed renewable energy infrastructure. We
									impact communities by helping them achieve almost net zero
									billing.
								</p>
							</div>
							<div className="col-md-5 text-center">
								{/* <img src={sgr} className="w-100" alt="Who We Are Image" /> */}
								<img src={gr} alt="Our Plan Image" className="w-100 pb-3" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default About;
