import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext.js";
import AdminLoginForm from "../../components/common/AdminLoginForm.tsx";
import Modal from "../../components/user/Modal.tsx";
import { adminlogin } from "../../utils/api/auth.js";


interface LoginPageProps {
  setCurrentPage?: (page: string) => void;
}

const LoginPage: React.FC<LoginPageProps> = ({ setCurrentPage }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    captcha: "",
    phonenumber: "",
    captchaInput: ""
  });

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [serverMessage, setServerMessage] = useState(null);
  const { login } = useAuth();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { username, password } = formData;

    if (formData.username && formData.password) {
      const response = await adminlogin(username, password);
      login(response.token, response.role); // Store the token and set the user as authenticated

      setServerMessage({ type: response.status, text: response.massage || 'Login successful!' });
      setFormData({ username: '', password: '', captcha: '' }); // Reset form fields on success
      setShowModal(true);
      setModalMessage("Login Successful!");
      navigate('/admin/group-offers');
    } else {
      setModalMessage("Please enter valid credentials.");
      setShowModal(true);
    }
  };

  const handleCloseModal = () => setShowModal(false);

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="mt-5"></div>
          <h2 className="line-title">LOGIN</h2>
          <AdminLoginForm
            formData={formData}
            onChange={handleChange}
            onSubmit={handleSubmit}
            isSignup={false}
          />

          <Modal show={showModal} handleClose={handleCloseModal} message={modalMessage} isTerms={false} />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
