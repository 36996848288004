import React from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';

interface ModalPDFProps {
    show: boolean;
    onHide: () => void;
    formData: any;
    onInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onGeneratePackage: () => void;
}

const ModalPDF: React.FC<ModalPDFProps> = ({ show, onHide, formData, onInputChange, onGeneratePackage }) => (
    <Modal show={show} onHide={onHide} size="lg" centered>
        <Modal.Header closeButton className="border-0">
            <Modal.Title className="fw-bold">Generate PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0 mt-0 text-center">
            <Container className="mt-4">
                <Row className="align-items-center mb-3">
                    <Col>
                        <Modal.Title className="fw-bold text-start">Company Name</Modal.Title>
                        <h5 className="text-success mb-3 text-start">Group Offer-ID :: Isdfuh97834</h5>
                    </Col>
                    <Col className="text-end">
                        <p>05/07/2024</p>
                        <Button variant="danger">PDF</Button>
                    </Col>
                </Row>



                <div className="p-3 border border-dashed mb-4">
                    <p className='text-start'>
                        We,
                        <input
                            type="text"
                            placeholder="Company Name"
                            className="mx-1 pdfInput"
                            style={{ width: '150px' }}
                        />,
                        at the
                        <input
                            type="text"
                            placeholder="Address"
                            className="mx-1 pdfInput"
                            style={{ width: '150px' }}
                        />,
                        accept the following orders to install onsite.
                        Each order includes components, an install kit, parts, and labor. I have the necessary inventory to fulfill these orders,
                        and all installations are to be completed on or before
                        <input
                            type="date"
                            placeholder="MM/DD/YYYY"
                            className="mx-1 pdfInput"
                            style={{ width: '120px' }}
                        />.
                        By signing this offer, I agree to report progress for each installation and confirm completion.
                    </p>
                </div>

                <h6 className="themeColor2 text-start">SKU-ID :: Isdfuh97834</h6>
                <p className='text-start'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s...</p>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">S.N.</th>
                            <th scope="col">Buyer ID</th>
                            <th scope="col">Order#</th>
                            <th scope="col">Handler ID</th>
                            <th scope="col">Quantity Set</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>Mark</td>
                            <td>Otto</td>
                            <td>@mdo</td>
                            <td>@mdo</td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>Jacob</td>
                            <td>Thornton</td>
                            <td>@fat</td>
                            <td>@fat</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Mark</td>
                            <td>Otto</td>
                            <td>@mdo</td>
                            <td>@mdo</td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>Jacob</td>
                            <td>Thornton</td>
                            <td>@fat</td>
                            <td>@fat</td>
                        </tr>
                    </tbody>
                </table>
                <Row className="g-2 align-items-end mb-3">
                    <Col md={8}>
                        <Form.Group controlId="installerCompanyAddress">
                            <Form.Label className='text-start d-flex'>Message for Company</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Message"
                                name="Message"
                                value={formData.Message}
                                onChange={onInputChange}
                                rows={3}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Button
                            variant="dark"
                            onClick={onGeneratePackage}
                            className="w-100 rounded-pill"
                        >
                            Generate Package
                        </Button>
                    </Col>
                </Row>
            </Container>

        </Modal.Body>
    </Modal>
);

export { ModalPDF };
