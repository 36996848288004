import React, { useState } from "react";
import { Modal as BootstrapModal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // Use useNavigate for navigation

interface ModalProps {
  show: boolean;
  handleClose: () => void;
  isTerms: boolean;
  message?: string;
}

const Modal: React.FC<ModalProps> = ({ show, handleClose, isTerms, message }) => {
  const [otp, setOtp] = useState<string[]>(new Array(6).fill("")); // Array to store OTP input
  const navigate = useNavigate(); // Use useNavigate for navigation

  // Handle OTP input changes
  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    if (/[^0-9]/.test(value)) return; // Prevent non-numeric input

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Focus on next input if value is entered
    if (value && index < 5) {
      document.getElementById(`otp-input-${index + 1}`)?.focus();
    } else if (!value && index > 0) {
      document.getElementById(`otp-input-${index - 1}`)?.focus();
    }
  };

  // Handle OTP submission
  const handleVerifyClick = () => {
    // Verify OTP (simple check here, you can add more logic)
    if (otp.every((digit) => digit !== "")) {
      navigate("/login"); // Navigate to after login page
    } else {
      // Optionally handle error, show message, etc.
      alert("Please enter all OTP digits.");
    }
  };

  return (
    <BootstrapModal show={show} onHide={handleClose} dialogClassName="modal-dialog-centered">
      <BootstrapModal.Header closeButton className=" border-0 pb-0">
        <BootstrapModal.Title className="fw-bold modal-title h4">
          {isTerms ? "Terms and Conditions" : "OTP Verification"}
        </BootstrapModal.Title>
      </BootstrapModal.Header>

      <BootstrapModal.Body className="mt-0 pt-3">
        {isTerms ? (
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </p>
        ) : (
          <>
            <h4 className="text-uppercase text-center">please enter the 6 digit number</h4>
            <p className="text-center mb-0">A one -time password has been sent to ********94</p>
            {/* <p className="text-center mb-0">Please enter OTP to verify</p> */}
            <div className="otp-input-fields d-flex justify-content-between">
              {otp.map((_, index) => (
                <input
                  key={index}
                  id={`otp-input-${index}`}
                  type="text"
                  maxLength={1}
                  className="otp__digit form-control text-center"
                  value={otp[index]}
                  onChange={(e) => handleOtpChange(e, index)}
                  onFocus={(e) => e.target.select()}
                />
              ))}
            </div>
          </>
        )}

        {/* Verify Button */}
        {!isTerms && (
          <Button
            variant="primary"
            className="create-group-offer-button2 w-50 mx-auto d-flex text-center justify-content-center"
            onClick={handleVerifyClick} // Trigger verification
          >
            Verify
          </Button>
        )}
        {/* Optionally display message if provided */}
        {message && <p className="mt-3 text-center">{message}</p>}
        <a href="javascript:;" className="text-center mt-3 d-flex justify-content-center theme-color text-decoration-none fw-bold">Resend OTP</a>
      </BootstrapModal.Body>
    </BootstrapModal>
  );
};

export default Modal;
