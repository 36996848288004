import React, { useEffect, useState } from 'react';
import { Alert, Col, Container, Form, Row, Spinner, Table } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import BackImg from '../../../Image/back-arrow.svg';
import del from '../../../Image/delete.svg';
import { buyerDetails } from '../../../utils/api/admin'; // Import your API function

interface BuyerHandlers {
    id: number;
    firstName: string;
    lastName: string;
    address: string;
    address2: string;
    city: string;
    state: string;
    zipCode: string;
    extRefNbr: string;
    buyer_id: number;
    QR: string;
    created_at: string;
}

interface BuyerData {
    id: number;
    buyer_set_name: string;
    buyer_set_id: string;
    created_at: string;
    buyerHandlers: BuyerHandlers[] | null;
}

const BuyerDetails: React.FC = () => {
    const { buyer_id } = useParams<{ buyer_id: string }>(); // Access the route parameter correctly
    const [data, setData] = useState<BuyerData[]>([]); // Start with an empty array
    const [subdata, setSubdata] = useState<BuyerHandlers[]>([]); // Start with an empty array
    const [loading, setLoading] = useState<boolean>(true); // Loading state
    const [error, setError] = useState<string | null>(null); // Error state

    const fetchBuyerDetails = async (buyer_id: number) => {
        try {
            const response = await buyerDetails(buyer_id) ; // BuyerData
            console.log(response);
            setData(response.buyer);
            setSubdata(response.buyerHandlers); // Assuming buyerHandlers is inside the first object
            
            setLoading(false); // Set loading to false once data is fetched
        } catch (err: any) {
            setError(err.message || 'Failed to fetch buyer details');
            setLoading(false); // Stop loading in case of error
        }
    };
    useEffect(() => {
        fetchBuyerDetails(buyer_id);
    }, []); // Empty dependency array ensures this runs once when the component mounts

    const handleDelete = (id: number): void => {
        setData(prevData => prevData.filter(row => row.id !== id));
    };

    return (
        <Container className="p-4">
            <Link to="/admin/buyer-sets" className="text-decoration-none text-black mb-0 active">
                <h4 className="mb-0 adminTitle">
                    <img src={BackImg} alt="Back" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
                    Buyer Details
                </h4>
            </Link>
            <div className="bg-white p-2 mt-4">
                <Row className="mb-3">
                    <Col md={4}>
                        <Form.Group controlId="buyerSetName">
                            <Form.Label>Buyer Set Name</Form.Label>
                            <Form.Control readOnly type="text" placeholder="Buyer Set Name" className="rounded-pill" value={data?.buyer_set_name || ''} />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="buyerSetID">
                            <Form.Label>Buyer Set ID</Form.Label>
                            <Form.Control readOnly type="text" placeholder="Buyer Set ID" className="rounded-pill" value={data?.buyer_set_id || ''} />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="buyerSetCreateDate">
                            <Form.Label>Buyer Set Create Date</Form.Label>
                            <Form.Control readOnly type="text" placeholder="Buyer Set Create Date" className="rounded-pill"  value={data?.created_at ? new Date(data.created_at).toLocaleDateString() : ''} />
                        </Form.Group>
                    </Col>
                </Row>
            </div>

            {loading ? (
                <div className="d-flex justify-content-center">
                    <Spinner animation="border" variant="primary" />
                </div>
            ) : error ? (
                <Alert variant="danger">
                    {error}
                </Alert>
            ) : (
                <Table striped bordered hover responsive className="bg-white">
                    <thead>
                        <tr>
                            <th>S.N.</th>
                            <th>Unique Handler ID</th>
                            <th>Buyer ID</th>
                            <th>QR Code</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {subdata.length > 0 ? 
                        subdata.map((row, index) => (
                            <tr key={row.id}>
                                <td>{index + 1}</td>
                                <td>{row.id}</td>
                                <td>{row.buyer_id}</td>
                                <td>
                                    <img src={row.QR} alt="QR Code" width="24" />
                                </td>
                                <td>
                                    <img
                                        src={del}
                                        alt="Delete"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handleDelete(row.id)}
                                    />
                                </td>
                            </tr>
                        )) : (
                            <tr className='text-center my-4'>
                            <td colSpan={5}>No data</td> {/* Set colSpan to the number of columns */}
                        </tr>
                        )}
                    </tbody>
                </Table>
            )}
        </Container>
    );
};

export default BuyerDetails;
