import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import TermsAndConditionPopup from '../../components/user/TermsAndConditionsPopup.tsx';
import { set_interest } from '../../utils/api/user.js';
import { FaThumbsUp } from 'react-icons/fa';
import { useAuth } from '../../AuthContext.js';

const Order: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  // const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isInterested, setIsInterested] = useState<string | null>('yes');
  const [isInterestedError, setIsInterestedError] = useState<boolean>(false);
  const [isCheckedError, setIsCheckedError] = useState<boolean>(false);

  const navigate = useNavigate(); // Initialize useNavigate
  const { logout, userProfile, setProfile } = useAuth();

  useEffect(() => {
    console.log(userProfile);
    if (userProfile.is_interested == "1")
      setShowSuccessModal(true);
  }, [userProfile]);

  const handleOpen = async () => {
    if (!isInterested) {
      setIsInterestedError(true);
      return;
    }

    try {
      const interestValue = isInterested === 'yes' ? '1' : '2';
      await set_interest(interestValue);
      if(interestValue) setProfile('is_interested', interestValue);
      setShowSuccessModal(true); // Show success modal after successful submission
    } catch (error) {
      console.error('Error setting interest:', error);
      alert('Failed to submit your interest. Please try again.');
    }
  };

  const handleClose = () => setShowModal(false);

  const handleCheckboxChange = () => {
    setIsCheckedError(false);

  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsInterestedError(false);
    setIsInterested(event.target.value);
  };

  const handleOkClick = () => {
    logout();
    navigate('/login');
  };

  return (
    <>
      <div className="bodyScroll">
        {!showSuccessModal && (
          <div>
            <div className="text-center">
              <h2 className="mb-4">Your Unique Offer Summary</h2>
            </div>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-8">
                  <div className="bg-white p-4 rounded shadow-sm">
                    <h2 className='themeColor2'>Description:</h2>
                    <p>This is a turnkey solution that includes and not limited to all the solar panel placement design, solution engineering, procurement, solar equipment installations, inspections and system turn-up.</p>
                    <p className=''>Tax, Permitting fees and other local charges are applied during the ordering process.
                    </p>
                    <ol className='list-group list-group-numbered mb-3 mt-3'>
                      <li className='list-group-item'>Synergy ComSol offers a group buying offer selective to the subdivision of your community. As soon as we receive interest to purchase from you and homes in the subdivision of your community., we initiate a niche Ordering invite only to you to place an order.</li>
                      <li className='list-group-item'>ComSol’s group offer is a turnkey Solar solution to install photovoltaic cells on your rooftop. Our sizing and system design eliminates the need for energy storage modules. Our solution is well engineered to serve up your electric power demands.</li>
                    </ol>
                    {/* <div className="row g-3">
                      <div className="col-12">
                        <h2 className="mb-3">Pricing</h2>
                      </div>
                      <div className="col-12">
                        <table className="table table-striped table-hover">
                          <thead>
                            <tr>
                              <th scope="col">S.R.</th>
                              <th scope="col">Line Items</th>
                              <th scope="col">Quantity</th>
                              <th scope="col">Size</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">1</th>
                              <td>6</td>
                              <td>20</td>
                              <td>1GB</td>
                            </tr>
                            <tr>
                              <th scope="row">2</th>
                              <td>3</td>
                              <td>10</td>
                              <td>1GB</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-12 col-md-6">
                        <p>Maintenance & Service Charge - ₹200/-</p>
                      </div>
                      <div className="col-12 col-md-6 text-end">
                        <h5 className="mb-0">Total Cost</h5>
                        <h6>₹ 3999.00/Mo</h6>
                      </div>
                    </div>
                    <hr /> */}
                    {/* <div className='pt-2' >
                      <div className="form-check d-flex align-items-center">
                        <input
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          className="form-check-input me-2"
                          name="acceptTC"
                          id="acceptTC"
                          required
                          type="checkbox"
                        />
                        <label htmlFor="acceptTC" className="form-check-label">
                          I Accept All{' '}
                          <span
                            style={{ color: 'blue', cursor: 'pointer' }}
                            onClick={() => setShowModal(true)}
                          >
                            Terms And Conditions
                          </span>
                        </label>

                        {isCheckedError && (
                          <small className="ms-3 text-danger">You need to agree with our Terms And Conditions</small>
                        )}
                      </div>
                    </div> */}

                  </div>
                  <div className="d-flex justify-content-between align-items-center my-4">
                    <div className="d-flex align-items-center">
                      <div className='d-block'>
                        <label className='w-100' style={{ fontWeight: 'bold', color: '#5d3b8e', fontSize: '18px', marginRight: '10px' }}>
                          I am interested. Please include me into this invite only list.
                        </label>
                        <br />
                        <div className="form-check form-check-inline me-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="interest"
                            id="yes"
                            required
                            value="yes"
                            checked={isInterested === 'yes'}
                            onChange={handleRadioChange}
                          />
                          <label className="form-check-label" htmlFor="yes">
                            Yes
                          </label>
                        </div>

                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="interest"
                            id="no"
                            
                            value="no"
                            checked={isInterested === 'no'}
                            onChange={handleRadioChange}
                          />
                          <label className="form-check-label" htmlFor="no">
                            No
                          </label>
                        </div>
                        {isInterestedError && (
                          <small className="text-danger">Please select your preference</small>
                        )}
                      </div>
                    </div>
                    <button id="Next-Button" className="create-group-offer-button2" onClick={handleOpen}>
                      Submit
                    </button>
                  </div>
                  <small className='text-white'><b> Disclaimer:</b> Once you receive the order details, you will have a chance to review the attractive offer and process the payment. If it does not meet your needs you will have the right to cancel.</small>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Modal Component */}
        <TermsAndConditionPopup show={showModal} handleClose={handleClose} />

        {/* Success Modal */}
        {showSuccessModal && (
          <div
            className="position-fixed top-50 start-50 translate-middle p-4 bg-white rounded shadow-lg text-center"
            style={{ zIndex: 1050 }}
          >
            <FaThumbsUp size={40} color="green" />
            <h4 className="mt-2">Thank you!</h4>
            <p className='m-0'>Thank you for your submission.</p>
            <p className='mb-1'>Reach out to you soon.</p>
            <button
              onClick={handleOkClick} // Navigate to the login page
              className="w-50 mx-auto create-group-offer-button2 btn btn-primary"
            >
              OK
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Order;
