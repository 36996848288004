import React from 'react';
import { Button, Modal } from 'react-bootstrap';

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSend: () => void;
  groupName: string;
  buyerSet: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onSend,
  groupName,
  buyerSet
}) => {
  return (
    <Modal show={isOpen} onHide={onClose} centered className="border-0">
      <Modal.Header closeButton className="border-0 pb-0">
        <Modal.Title className="fw-bold text-center">Send Group Offer Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0 mt-0">
        <h6 className="text-center mb-3 theme-color mx-5">
          Do you want to send "{groupName}" to the "{buyerSet}" buyer set?
        </h6>
        <Button
          className="w-25 mx-auto btn btn-dark d-flex justify-content-center text-center create-group-offer-button2"
          onClick={onSend}
        >
          Send
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;
