import React from 'react';
import Video from '../../Image/image 12.png';

const Title: React.FC = () => {
    return (
        <div>
            <h2 className="line-title">TITLE</h2>
            <p className='text-center'>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                when an unknown printer
            </p>
            <img src={Video} alt="Descriptive Slide" className="w-75  mt-5 mx-auto d-flex" />
        </div>
    );
};

export default Title;
