import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import csvFile from '../../../Image/csvFile.svg';
import failImage from '../../../Image/fail.gif'; // Import your fail image here
import success from '../../../Image/success.gif';

interface Props {
  onImageSelect: (imageFile: string) => void; // Add this prop to handle image selection
}

const ImageUploadComponent: React.FC<Props> = ({ onImageSelect }) => {
  const [statusMessage, setStatusMessage] = useState(''); // State to hold status message
  const [statusImage, setStatusImage] = useState<string | null>(null); // Allow both string and null

  // Handle QR image upload with .csv file check
  const handleQrUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; // Use optional chaining

    if (file) {
      const isCsvFile = file.type === 'text/csv';  // Check for CSV MIME type
      if (isCsvFile) {
        setStatusMessage('Upload successful');  // Show success message
        setStatusImage(success);  // Show success icon after a successful CSV upload
        onImageSelect(file.name); // Pass the file name (or any relevant file data) back to the parent
      } else {
        setStatusMessage('Upload failed: Please upload a .csv file'); // Show error message
        setStatusImage(failImage); // Show fail icon if not a CSV
      }
    }

    // Clear status on file change for better UX
    setStatusMessage('');
    setStatusImage(null);
  };

  return (
    <div className='row'>
      <Col md={10} className="d-flex align-items-center">
        <div className="forQr position-relative text-center">
          {/* Display status message or default icon */}
          {statusMessage ? (
            <p className={`mb-0 ${statusImage === success ? 'text-success' : 'text-danger'}`}>
              {statusMessage}
            </p>
          ) : (
            <img
              className="m-auto d-flex qrImg w-100 forBuyerQr"
              src={csvFile}  // Show default csvFile icon initially
              alt="Upload a .csv file"
            />
          )}
          <input 
            className='hideInput' 
            type="file" 
            onChange={handleQrUpload} 
            accept=".csv" 
            aria-label="Upload CSV file"
          />
        </div>
      </Col>

      <Col md={2} className="d-flex align-items-end justify-content-end">
        {statusImage && ( // Show status icon only if an upload attempt has been made
          <img
            className="w-100 mb-3"
            src={statusImage}
            alt="Status Icon"
          />
        )}
      </Col>
    </div>
  );
};

export default ImageUploadComponent;
