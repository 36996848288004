import React from 'react';
import { Modal } from 'react-bootstrap';

interface ModalSuccessProps {
    show: boolean;
    onHide: () => void;
    image: string;
}

const ModalSuccess: React.FC<ModalSuccessProps> = ({ show, onHide, image }) => (
    <Modal show={show} onHide={onHide} size="lg" centered>
        <Modal.Header closeButton className="border-0" />
        <Modal.Body className="pt-0 mt-0 text-center">
            <h2 className="fw-bold mb-4">Package Successfully Generated!</h2>
            <img src={image} alt="success gif" style={{ width: '100px', height: '100px' }} />
        </Modal.Body>
    </Modal>
);

export { ModalSuccess };
