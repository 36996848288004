import React, { FC } from 'react';
import sgr from '../../Image/image 12.png';
import gr from '../../Image/image 13.png';

interface AboutProps {
    heading: string;
}

const About: FC<AboutProps> = ({ heading }) => {
    return (
        <div className='bodyScroll'>
            <div className="d-flex justify-content-center line-title my-5">
                <h2>{heading}</h2>
            </div>
            <div>
                <div className="container">
                    <div className="card overflow-hidden mb-4 p-4">
                        {/* Section: Who We Are */}
                        <div className="row align-items-center mb-5 border-bottom pb-4">
                            <div className="col-md-7">
                                <h2 className="mb-3">WHO WE ARE</h2>
                                <p className="text-muted">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                    when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </p>
                                <ul className="list-unstyled">
                                    <li className="mb-2">
                                        <i className="bi bi-dot me-2"></i>
                                        Lorem the 1500s, when an unknown printer took a galley of type and scrambled it.
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-5 text-center">
                                <img src={sgr} className="w-100" alt="Who We Are Image" />
                            </div>
                        </div>

                        {/* Section: What Is Our Plan */}
                        <div className="text-center mt-5">
                            <h2 className="mb-4">— WHAT IS OUR PLAN —</h2>
                            <div className="row align-items-center">
                                <div className="col-md-5">
                                    <img src={gr} alt="Our Plan Image" className="w-100 pb-3" />
                                </div>
                                <div className="col-md-7">
                                    <ul className="list-dotted text-start g-5">
                                        <li className="mb-4">
                                            Lorem the 1500s, when an unknown printer took a galley of type.
                                        </li>
                                        <li className="mb-4">
                                            Lorem the 1500s, when an unknown printer took a galley.
                                        </li>
                                        <li className="mb-4">
                                            Lorem the 1500s, when an unknown printer took a galley of type and scrambled it.
                                        </li>
                                        <li className="mb-4">
                                            Lorem the 1500s, when an unknown printer took a galley.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
